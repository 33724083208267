import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack';
import projectsAPI from '../utils/projectsAPI';
import localProjectsAPI from '../utils/localProjectsAPI';
import syncStore from '../utils/syncStore';



const SyncItem = ({ project }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setProjectStatus } = syncStore()[1]
  const msg = project.title
  const setStatus = (status) => setProjectStatus({ [project.id]: status })
  useEffect(() => {
    const first = enqueueSnackbar(msg)
    setStatus('pending')
    const thener = () => {
      closeSnackbar(first) 
      setStatus(undefined)
      enqueueSnackbar(`${msg} done`, {
        variant: 'success',
        autoHideDuration: 2000,
      })
    }
    const catcher = (err) => {
      closeSnackbar(first)
      console.log(err)
      if(!err.response){
        enqueueSnackbar(`${msg} failed – no network`, {
          variant: 'error',
          persist: true,
        })
        setStatus(undefined)
      }
      else{
        enqueueSnackbar(`${msg} failed – Please check error`, {
          variant: 'error',
          persist: true,
        })
        setStatus('error')
      }
    }
    // projectsAPI.saveOrCreateRequest was deprecated
    // projectsAPI.saveOrCreateRequest(project, thener, catcher)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, closeSnackbar])
  return null
}

function Syncer() {
  return null
  const [unsyncedProjects, setUnsyncedProjects] = useState([])
  useEffect(() => {
    const fetchUnsynced = () => {
      const projects = localProjectsAPI.getUnsynced()
      setUnsyncedProjects(projects)
    }
    fetchUnsynced()
    window.addEventListener('online', fetchUnsynced)
    return () => window.removeEventListener('online', fetchUnsynced)
  }, [])
  console.log(unsyncedProjects)
  return (
    <div>
      {unsyncedProjects.map(project => <SyncItem key={`proj-sync-${project.slug}`} project={project} />)}
    </div>
  );
}



export default Syncer
