// eslint-disable-next-line no-unused-vars
import { Delete, DeleteOutlined } from '@material-ui/icons';
import React from 'react';
import { view } from 'react-easy-state';
import routes from '../routes';
import useSpreadsheetSteps from '../steps/useSpreadsheetSteps';
import ButtonLink from '../ui/ButtonLink';
import Errors from '../ui/Errors';
import MenuBar from '../ui/MenuBar';
import SyncStatus from '../ui/SyncStatus';
import TitleWithBar from '../ui/TitleWithBar';
import { computeProjectTotal } from '../utils/pricing';
import ButtonWithConfirmation from './ButtonWithConfirmation';
import store from './newProjectStore';
import NoteInput from '../ui/NoteInput';
import RoomListItem, { PIECE_HUMIDE, PIECE_SECHE }  from './room/RoomListItem/index';





function ProjectRoomIndex({ history }) {
  const { project } = store
  const { version: currentVersion } = store.project
  const { roomTypes } = useSpreadsheetSteps()
  if (!currentVersion.rooms){
    currentVersion.rooms = []
  }
  const { rooms: allRooms = [] } = currentVersion
  const roomOfType = (roomType) => allRooms.find(r => r.roomType === roomType)
  const humid = allRooms.filter(r => r.roomType === PIECE_HUMIDE)
  const dry = allRooms.filter(r => r.roomType === PIECE_SECHE)

  const infoDOM = <React.Fragment>
    <div className="bg-light-grey rounded-xl py-6 px-4 my-6 sm:mb-16 sm:mt-8">
      <div className="text-blue mb-2">
        Notes
      </div>
      <NoteInput 
        defaultValue={currentVersion.note}
        onChange={(value) => currentVersion.note = value}
      />
    </div>
  </React.Fragment>


  const deleteButton = <ButtonWithConfirmation 
    className="mr-4"
    buttonText={<DeleteOutlined />}
    variant='primary'
    color="default"
    dialogProps={{
      title: `Voulez-vous vraiment effacer ${project.title}?`,
      content: "Cette action est irréversible",
      onAccept: () => store.deleteDraft(history),
    }}
  />

  const saveButton = <div className="mr-4" >
    <SyncStatus project={project} />
  </div>
  const actions = <div className="flex">
    { saveButton }
    { deleteButton }
    <ButtonLink variant="contained" color="secondary" to={{
      pathname: routes.projects.show(project.slug).quote,
      state: { from: routes.projects.show(project.slug).rooms.index },
    }}
    >Réviser</ButtonLink>
  </div>

  return (
    <div>
      <MenuBar 
        backTo={routes.projects.show(project.slug).versions.index}
        right={actions}
        className="sticky top-0"
      />
    
      <div className="container mx-auto pb-4 sm:pb-8 pt-8 sm:pt-16">
        <TitleWithBar
          className="text-lg"
          title={project.title}
          right={<div className="text-dark-grey">N<sup>o</sup>{project.internal_id}</div>}
        />
        <Errors errors={project.errors} />
        {/* { infoDOM } */}
        <div className="flex justify-end my-2">
          <div className="mr-2">
            {currentVersion.rooms.reduce((prev, r) => prev + r.steps.length, 0)} étapes
          </div>
          <div className="text-blue font-bold">
            CHF {computeProjectTotal(project).toLocaleString()}
          </div>
        </div>
        {roomTypes.map(roomType => {
          const isNameable = [PIECE_HUMIDE, PIECE_SECHE].includes(roomType)
          if (!isNameable) {
            const existingRoom = roomOfType(roomType)
            return <RoomListItem
              onCreate={() => store.addRoom({ name: roomType, roomType }, history)}
              project={project}
              roomType={roomType}
              room={existingRoom}
            />
          }
          const list = roomType === PIECE_HUMIDE ? humid : dry
          return <>
            {
              list.map((r) => <RoomListItem
                project={project}
                roomType={roomType}
                room={r}
                isNameable
              />)
            }
            <RoomListItem
              onCreate={(name) => store.addRoom({ name, roomType }, history)}
              project={project}
              roomType={roomType}
              requireName={true}
              isNameable
            />
          </>
        })
        }
      </div>
    </div>
  )
}

export default view(ProjectRoomIndex)
