import PrintIcon from '@material-ui/icons/Print';
import React, { useEffect, useMemo, useState } from 'react';
import { view } from 'react-easy-state';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import routes from '../routes';
import ButtonLink from '../ui/ButtonLink';
import CardItem from '../ui/CardItem';
import MenuBar from '../ui/MenuBar';
import SyncStatus from '../ui/SyncStatus';
import TitleWithBar from '../ui/TitleWithBar';
import projectsAPI from '../utils/projectsAPI';
import Loader from '../ui/Loader';


const colCount = 6

function ProjectItem({ project, tdClassName = '', canPrint}){
  const icon_ = canPrint ? <Link to={routes.projects.show(project.slug).quote} className="hidden sm:block">
    <PrintIcon className="opacity-50" />
  </Link> : <Link to={routes.projects.show(project.slug).rooms.index} className="hidden sm:block">
      <PrintIcon className="opacity-50" />
    </Link>
  return <CardItem>
    <Link
      to={routes.projects.show(project.slug).versions.index}
      className=" group cursor-pointer flex flex-row-reverse justify-between sm:block"
    >
      <div className="justify-between flex font-bold group-hover:underline">
        <div>
          {project.title}
        </div>
        {icon_}
        <SyncStatus project={project} />
      </div>
      { project.internal_id && <div className="text-right text-smaller text-blue sm:mt-4">
        n<sup>o</sup> {project.internal_id}
      </div>}
    </Link>
  </CardItem>
}

// eslint-disable-next-line no-unused-vars
function ProjectsHeader(){
  return <tr className="text-dark-grey text-sm bg-light-grey">
    <td className="pb-4 pt-4"></td>
    <td className="pb-4 pt-4">Agent</td>
    <td className="pb-4 pt-4">Date</td>
    <td className="pb-4 pt-4">Pièces</td>
    <td className="pb-4 pt-4">CHF</td>
    <td></td>
  </tr>
}

function Title({ title, className }) {
  return <TitleWithBar title={title} />
}
// function Title({ title, className }) {
//   return <div className={`text-lg py-2 border-b-2 border-yellow mb- sm:mb-8 ${className}`}>
//       {title}
//     </div>
// }

const Grid = styled.div`
  @media (min-width: 768px){
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }
`
function ProjectList() {
  const [allProjects, setProjects] = useState({})
  useEffect(() => {
    (async function(){
      const newProjs = await projectsAPI.index()
      setProjects(newProjs)
    })()
  }, [])
  const emptyRow = (classN) => <tr> <td colSpan={colCount} className={`pt-8 ${classN}`}></td></tr>

  const { unsyncedProjects, projects } = allProjects
  const byCategory = useMemo(() => projects && projects.reduce((prev, curr) => {
    return (curr.version && curr.version.locked) ?
      {
        ...prev,
        locked: [...prev.locked, curr]
      }
      : {
        ...prev,
        drafts: [...prev.drafts, curr]
      }
  }, {
    drafts: [...unsyncedProjects],
    locked: [],
  }), [projects, unsyncedProjects])
  
  const createFirst = <div className="container mx-auto flex justify-center py-24">
    <ButtonLink color="secondary" to={routes.projects.new}> Créer mon premier devis </ButtonLink>
  </div>

  const renderList = () => {
    if(!Object.entries(allProjects).length){
      return <Loader withContainer notice="Chargement des Projets" />
    }
    if(projects && !projects.length){
      return createFirst
    }

    return (
      <div className="container mx-auto py-4 sm:py-8">
        {byCategory && byCategory.drafts.length && <>
          <Title title="Brouillons" />
          <Grid className="my-4">
            {byCategory.drafts.map(p => <ProjectItem project={p} tdClassName="bg-light-grey" />)}
          </Grid>
          {emptyRow('bg-light-grey')}
        </>}
        {emptyRow('')}
        {byCategory && (byCategory.locked.length > 0) && <>
          <Title title="Devis Passés" />
          <Grid className="my-4">
            {byCategory.locked.map(p => <ProjectItem key={p.id} project={p} canPrint />)}
          </Grid>
        </>
        }
      </div>
    )
  }
  return <React.Fragment>
    <MenuBar title={"Mes Devis"} right={<ButtonLink color="secondary" to={routes.projects.new}> nouveau devis </ButtonLink>}/>
    { renderList() }
      {/* <table className="w-full container mx-auto">
        <tbody>
          <ProjectsHeader />

        {allProjects ? body() : <tr><td colSpan="6"><Loader withContainer={true} /></td></tr>}
        </tbody>
      </table> */}
  </React.Fragment>
}

export default view(ProjectList)
