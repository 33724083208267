import React, { useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';


const TextInputS = styled(TextField)`
  ::-webkit-input-placeholder{
    color: blue;
  }
`

const Div = styled.div`
  color: var(--blue);
  line-height: 1.2;
  padding-top: 5px;
  padding-bottom: 5px;
`
const NoteInput = ({ onChange, defaultValue, placeholder, ...props }) => {
  const [showInput, setShowInput] = useState(false)

  const [disableUnderline, setDisableunderline] = useState(true)
  const inputRef = useRef()
  useEffect(() => {
    if (!inputRef || !inputRef.current){
      return
    }
    if(defaultValue === ''){
      inputRef.current.value = ''
    }
  }, [inputRef, defaultValue])

  if (!showInput){
    return <Div onClick={() => setShowInput(true)}>
      {defaultValue || placeholder}
    </Div>
  }
  return <div>
    <TextInputS
      type="textarea"
      id="note"
      autoFocus
      multiline={true}
      rows={1}
      rowsMax={4}
      margin="none"
      defaultValue={defaultValue}
      onChange={({ target: { value: note } }) => onChange(note)}
      onFocus={() => setDisableunderline(false)}
      onBlur={() => setDisableunderline(true)}
      className="w-full"
      placeholder={placeholder}
      InputProps={{
        disableUnderline,
        style: {
          color: 'var(--blue)',
        },
        inputRef
      }}
      {...props}
    />
  </div>
}

export default NoteInput