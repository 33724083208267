import React, { useCallback, useState } from 'react'
import { view } from 'react-easy-state'
import StepForm from '../StepForm'
import { TextField, InputAdornment, Button } from '@material-ui/core'
import Edit from '@material-ui/icons/Edit'

import parentStyles from '../RoomSummary.module.css'
import styles from './StepRow.module.css'

import { computeStepPrice } from '../../utils/pricing'
import Step from '../room/Step'

export default view(({ index, style, room, step, editable, previewOnly, withUnit, className }) => {
  
    const hasNote = step.note && step.note.length
    const [noteOpen, setNoteOpen] = useState(false)
    const onRemoveStep = useCallback((s) => {
      room.steps = room.steps.filter(ss => ss !== step)
    }, [step])
    const updateStep = useCallback((s) => {
      if (step.sequence && s.quantity !== step.quantity){
        step.sequence.forEach(sId => {
          const child = room.steps.find(s => s.id === sId)
          if(child){
            child.quantity = s.quantity
          }
        })
      }
      step.quantity = s.quantity
      step.note = s.note
      step.price = s.price
    }, [step])

    const quantityField = (step) => {
      return (!editable || previewOnly) ? `${step.quantity || '–'} ${step.unit}` : <TextField
        value={step.quantity}
        name={"quantity"}
        onChange={({ target: { name, value } }) => updateStep({
          [name]: value,
        })}
        required={true}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">{step.unit}</InputAdornment>
        }}
      />
    }

  const stepForm = <StepForm room={room} step={step} onStepSaved={() => setNoteOpen(false)} /> 
  return renderAsGrid()
  
  function renderAsGrid(){
    if(window.innerWidth < 767){
      return <Step onRemoveStep={onRemoveStep} onUpdateStep={updateStep} step={step} />
    }
    const tdClasses = "px-2 py-4 light-bottom-border"
    return <div style={style} className={[className, 'contents step-row'].join(' ')}>
      <div className={`${tdClasses} ${styles.stepRowIndex}`}>
      </div>
      <div className={`${tdClasses}`}>
        <div>
          <div>{step.description}</div>
          {hasNote && <div className={`pt-2 text-blue text-sm`}>Note: {step.note}</div>}
        </div>
      </div>
      <div className={`${tdClasses} whitespace-no-wrap `}>
        <div>
          {quantityField(step)}
        </div>
      </div>
      {
        withUnit && <div className={`${tdClasses} whitespace-no-wrap step-unit-price`}>
          CHF {step.price}
      </div>
      }
      <div className={`${tdClasses} whitespace-no-wrap text-right computed-step-price`}>
        CHF {computeStepPrice(step).toLocaleString()}
      </div>
      {!previewOnly && <div className={`${tdClasses}`}>
        <Edit className="cursor-pointer opacity-50" onClick={() => setNoteOpen(true)} />
        {noteOpen && stepForm}
      </div>}
    </div> 
  }
  
})