import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ShoppingCartOutlined } from '@material-ui/icons';


const Pill = styled.div`
  position: absolute;
  top: 0;
  font-size: 10px;
  right: 0;
  background-color: var(--bg-yellow);
  padding: 2px 5px;
  border-radius: 20px;
  min-width: 15px;
  color: var(--blue);
  transform: translateX(50%);
`

const AnimatedCart = styled(ShoppingCartOutlined)`
  color: var(--icon-grey);
  &.added{
    animation: bounce .4s 1;
  }
  &.removed{
    animation: ciao .6s 1 ease-out;
  }
  @keyframes ciao{
    0%, 100%{ transform: translateY(0px); opacity: 1; }
    70%{ transform: translateY(3px) scale(.95); opacity: .3; }
    71%{ transform: translateY(0px); opacity: 0; }
  }
  @keyframes bounce{
    0%, 100%{
      transform: translateY(0px) rotate(0deg);
    }
    25%{
      transform: translateY(-3px) scale(1.4) rotate(-5deg);
    }
    75%{
      transform: translateY(-3px) scale(1.4) rotate(5deg);
    }
  }
`

function BumpingCart({ count }) {

  const oldCount = useRef(count)
  const node = useRef(null)
  const [animateClass, setAnimateClass] = useState('')
  
  useEffect(() => {
    if(oldCount.current > count){
      setAnimateClass('removed')
    }
    else if (oldCount.current < count){
      setAnimateClass('added')
    }
    oldCount.current = count
  }, [count])

  useEffect(() => {
    if(node.current){
      console.log('finished', node)
      node.current.addEventListener('animationend', () => {
        setAnimateClass('')
      })
    }
  }, [node])
  return (
    <div className="text-2xl ml-2 relative">
      <AnimatedCart ref={node} fontSize={'inherit'} className={`font-smaller ${animateClass}`} />
      {count > 0 && <Pill>{count}</Pill>}
    </div>
  );
}

export default BumpingCart;