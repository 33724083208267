import React, { useState } from 'react'

import QuoteHeader from './QuoteHeader'
import QuoteDetails from './QuoteDetails'
import QuoteStatistics from './QuoteStatistics'
import RoomSummary from '../RoomSummary'
import Switch from '@material-ui/core/Switch';
import MenuBar from '../../ui/MenuBar';
import Button from '@material-ui/core/Button';

import store from '../newProjectStore'
import { Link } from 'react-router-dom';
import { ArrowBack, Print, SyncProblem, Receipt } from '@material-ui/icons';
import routes from '../../routes';
import TableHeader from '../TableHeader';
import projectController from '../../utils/projectController';
import DownloadExcel from '../../ui/DownloadExcel';


export default function ({ history, location, project }){
  let backTo = !project.version.locked ? routes.projects.show(project.slug).rooms.index : routes.projects.show(project.slug).versions.index
  if( location && location.state){
    const { state: { from } } = location
    backTo = from
  }

  const rooms = project.version.rooms || []
  const [showPrintable, setShowPrintable] = useState(true)

  const switcher = <div className="flex items-center ml-6">
    <Switch
      checked={showPrintable}
      onChange={({ target: { checked } }) => setShowPrintable(checked)}
      value="printable"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
    <div className="text-sm">Aperçu</div>
  </div>

  const unsynced = store.project && (store.project.status === 'unsynced') ? (
    <Button onClick={() => store.save()}><SyncProblem /></Button>
   ) : null
  const lockButton = () => <Button 
    variant="contained" 
    color="primary" 
    onClick={() => projectController.lock(store.project, history)} 
  > 
      Envoyer le devis
  </Button>

  const printButton = () => <div>
    <DownloadExcel version={project.version}/>
    <Print onClick={window.print}/>
  </div>
  const headerButton = project.version.locked ? printButton() : lockButton()

  const left = <div className="flex items-center">
    <Link to={backTo}><ArrowBack /></Link>
    {/* {switcher} */}
  </div>
  const appHeader = <MenuBar left={left} title={{
      top: project.client.name,
      bottom: project.title,
    }}
    right={<>
      {unsynced}
      {headerButton}
    </>}
  />
  let stepsCount = 0
  return (
    <div className="pb-4"
      style={{
        "counter-reset": "listCounter"
      }}
    >
      { appHeader }
      {!showPrintable && <QuoteStatistics project={project} />}
      <div className={`container mx-auto ${showPrintable ? 'print' : ''}`}>
        {showPrintable && <QuoteHeader />}
        {showPrintable && <QuoteDetails project={project} /> }

        {/* {showPrintable && <TableHeader withGridWrapper={true} previewOnly={true} />} */}
        {rooms.map((room, i) => {
          const render = <RoomSummary prevStepsCount={stepsCount} className={'mb-12'} withName={true} withNote={true} previewOnly={true} room={room} />
          stepsCount += room.steps.length
          return render
        })}
      </div>
    </div>
  )
}