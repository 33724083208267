import routes from '../routes'
import axios from 'axios'
import localProjectsAPI from './localProjectsAPI';

import { computeProjectTotal } from "./pricing";


export const ERROR_NAME = "NotNetworError"

const mapProjectToPOST = ({ version, versions, ...project }) => (!version ? project : {
  ...project,
  version: {
    ...version,
    price: computeProjectTotal ({ version }),
    rooms_count: version.rooms.length
  },
})

const projectsAPI = {
  index: async () => {
    const { data: { data: projects } } = await axios.get(routes.api.projects.index)
    const unsyncedProjects = await localProjectsAPI.index()
    return {
      unsyncedProjects,
      projects,
    }
  },
  get: async (projectSlug, versionId) => {
    const versionPath = versionId ? `/version/${versionId}` : ''
    try{
      const { data: { data }} = await axios.get(`${routes.api.projects.show(projectSlug)}${versionPath}`)
      return data
    }
    catch (err) {
      let project = localProjectsAPI.get(projectSlug)
      if(project){
        return project
      }
      throw err
    }
  },
  saveOrCreate: async (data) => {
    const url = data.id ? routes.api.projects.update(data.slug) : routes.api.projects.create
    // only PUT if the project was already synced,
    // ie. if it has an ID
    const method = data.id ? 'put' : 'post'
    const mappedProject = mapProjectToPOST(data)
    try{
      var res = await axios[method](url, mappedProject)
      const { data: { data: project } } = res
      return {
        ...project,
        status: 'synced',
      }
    } catch(err){
      if(!err.response){
        const unsynced = {
          ...data,
          status: 'unsynced',
        }
        localProjectsAPI.save(unsynced)
        return unsynced
      }
    }
  },
  create: async (data) => {
    if(data){
      delete data.errors
    }
    return  await projectsAPI.saveOrCreate({
      ...data,
    })
  },
  save: async (data) => {
    return await projectsAPI.saveOrCreate(data)
  }
}
export default projectsAPI