import React, { useState, useCallback } from 'react';
import { useRoomSteps } from './useRoomSteps';
import styled, { css } from 'styled-components';
import { Button, TextField } from '@material-ui/core';
import { Done } from '@material-ui/icons';

const Toolbar = styled.div`
  position: fixed;
  bottom: 5px;
  left: 20px;
  right: 20px;
  z-index: 1000;
  transition: transform .10s ease-in-out;
  display:flex;
  justify-content: center;
  ${props => css`
    transform: translateY(${props.open ? '0%' : 'calc(100% + 5px)'});
    transition-delay: ${props.open ? '0ms' : '1500ms'};
  `}
`;


export const SelectedToolbar = () => {
  const { selected, dispatch, categories } = useRoomSteps();
  const [quantity, setQuantity] = useState()
  const [success, setSuccess] = useState(false)
  
  const addSelected = () => {
    dispatch({ type: 'ADD_SELECTION', payload: { quantity, categories }})
    setSuccess(true)
    setTimeout(() => {
      setSuccess(false)
      setQuantity(undefined)
    }, 1700)
  }

  const ui = (<>
    <span>
      <span className="font-bold">
        {selected.length}
      </span>
          &nbsp;sélectionnée{selected.length > 1 && 's'}
    </span>
    <div
      className="mx-3"
      style={{
        '--border-color': 'white',
      }}
    >
      <TextField
        id="quantity"
        name="quantity"
        defaultValue={quantity}
        placeholder="Quantité"
        color="primary"
        onChange={useCallback(({ target: { value: quantity } }) => setQuantity(quantity), [setQuantity])}
        onFocus={useCallback(({ target }) => target.select(), [])}
        type="number"
        margin="none"
        InputProps={{
          style: {
          },
          inputProps: {
            inputMode: 'decimal',
            style: {
              color: 'white'
            }
          },
        }}
      />
    </div>
    <Button variant="contained" color="secondary" onClick={addSelected}>ok</Button>
  </>)

  const successNotice = (<div className="flex items-center">
    <Done />
    <span className="pl-2">Étapes ajoutées!</span>
  </div>)
  return <Toolbar open={Boolean(selected.length)}>
  <div className="container px-0 sm:px-2 mx-auto">
    <div className="bg-blue rounded-md text-white p-2 flex items-center justify-between">
        {success ? successNotice : ui}
    </div>
    
  </div></Toolbar>;
};
