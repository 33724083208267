import React, { useState, useEffect } from 'react'
import routes from '../routes';
import axios from 'axios';
import Quote from './quote/Quote';
import Loader from '../ui/Loader';

function ProjectVersion({ match: { params: { projectSlug, vId } } }) {
  const [project, setProject] = useState()
  useEffect(() => {
    async function get(){
      const url = routes.api.projects.version(projectSlug, vId)
      const { data: { data: project } } = await axios.get(url)
      setProject(project)
    }
    get()
  }, [projectSlug, vId])

  if(!project){
    return <Loader />
  }
  return (
    <Quote project={project} />
  )
}

export default ProjectVersion
