import React from 'react'

function NoResult({ title, description }) {
  return (
    <div className="flex flex-col justify-content justify-center text-center w-full h-full">
      <div className="text-2xl mb-4">
        { title }
      </div>
      <div>
        { description }
      </div>
    </div>
  )
}

export default NoResult
