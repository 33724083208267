import React from 'react'
import generateGlobalHook from 'use-global-hook'

const initialState = {}

// pas payload as {[projectId]: state}
const actions = {
  setProjectStatus: (store, payload) =>{
    store.setState({
      ...store.state,
      ...payload
    })
  }
}
const syncStore =  generateGlobalHook(React, initialState, actions)
export default syncStore
