import React from 'react'

function TitleWithBar({ title, className = 'text-2xl', right }) {
  return (
    <div className={`room-title sm:flex border-b-4 border-yellow justify-between ${className}`}>
      <div>
        {title}
      </div>
      { right }
    </div>
  )
}

export default TitleWithBar
