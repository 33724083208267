import { TextField } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useState } from 'react';
import { view } from 'react-easy-state';
import { PIECE_HUMIDE, PIECE_SECHE } from './index';

const EditableName = view(({ isOpen, slug, roomType, name, onChangeName }) => {
  const [editingName, setEditingName] = useState(false);
  const isNameable = [PIECE_HUMIDE, PIECE_SECHE].includes(roomType)
  const prevent = (evt) => {
    evt.stopPropagation()
    evt.preventDefault()

  }
  const handleNameClick = (evt) => {
    if (!isNameable) {
      
    }
    setEditingName(true)
    prevent(evt)
  }
  if (!isOpen && !editingName) {
    return <div onClick={handleNameClick} className="inline pr-2">
      {name || roomType || 'Sans Titre'}
    </div>
  }
  if (editingName) {
    return <TextField
      defaultValue={name}
      onClick={e => prevent(e)}
      placeholder={roomType === PIECE_HUMIDE ? "Cuisine" : "Salon"}
      inputProps={{
        onKeyDown: evt => {
          if (evt.code === 'Enter') {
            console.log(evt)
            evt.target.blur()
          }
        }
      }}
      onBlur={({ target: { value } }) => { onChangeName(value); setEditingName(false); }}
      autoFocus={true} />;
  }
  // return placeholder
  return <span className="w-full flex-grow" onClick={(e) => { e.stopPropagation(); setEditingName(true); }}>
    {name || roomType}
    {isOpen && <Edit fontSize="small" className={"opacity-25 ml-2"} />}
  </span>;
});

export default EditableName