import React, { Component } from 'react'
import { view } from 'react-easy-state'
import { Paper, Button, Stepper, Step, StepLabel, StepButton, TextField  } from '@material-ui/core';

import Loader from '../ui/Loader'
import ConstructionStepsForm from './ConstructionStepsForm'
import RoomSummary from './RoomSummary'

import store from './newProjectStore'
import tmpRoom from './tmpRoomStore'
import routes from '../routes'
import { computeRoomTotal } from '../utils/pricing';
import ButtonWithConfirmation from './ButtonWithConfirmation';
import NoResult from '../ui/NoResult';
import MenuBar from '../ui/MenuBar';
import { ArrowBack } from '@material-ui/icons';
import useSpreadsheetSteps from '../steps/useSpreadsheetSteps';


class ProjectRoomEdit extends Component {
  state = {
    room: {
      steps: [],
    },
    gotSteps: true,
    availableSteps: [],
    roomSlug: this.props.match.params.id,
    summaryOpen: false,
    cancelOpen: false,
    currentStep: 0,
    stepCompleted: []
  }
  componentDidMount(){ 
    const room = store.project.version.rooms.find((r) => r.slug === this.state.roomSlug)
    // this JSON trick unbinds this from the store's observer
    tmpRoom.room = JSON.parse(JSON.stringify(room))
    const availableSteps = this.props.getSteps(this.props.provider, room.roomType)
    this.setState({ availableSteps })
  }
  renderHeader(){
    const { room } = tmpRoom
    const { history } = this.props
    const back = <ButtonWithConfirmation
      buttonIcon={<ArrowBack/>}
      dialogProps={{
        title: "Voulez-vous vraiment annuler?",
        content: "Vos changements effectués sur cette chambre seront perdus. Cette opération est irréversible",
        acceptText: "Annuler mes changements",
        cancelText: "Continuer à éditer",
        onAccept: () => history.push(routes.projects.show(store.project.slug).rooms.index),
      }}
    />
    return <MenuBar left={back} 
      title={{
        top: room.roomType,
        bottom: room.name
      }}
      right={{
        top: `${room.steps.length} étapes`,
        bottom: `CHF ${computeRoomTotal(room).toLocaleString()}`
      }}
    />
  }
  saveRoomToStore(){
    const { room } = tmpRoom
    store.saveRoom(room, this.props.history)
  }
  renderSummary() {
    const { room } = tmpRoom
    return <div className="container mx-auto">
      <div className="note-container">
        <span>Note:</span>
        <TextField
          value={room.note}
          multiline={true}
          rows={1}
          rowsMax={4}
          fullWidth
          onChange={({ target: { value } }) => room.note = value}
        />
      </div>
    </div>
  }

  renderProgress(){
    const progressSteps = [
      'Sélectionner les étapes',
      'Ajouter les mesures',
      'Ajouter une note',
    ]
    const { room: { stepCompleted} } = tmpRoom
    const { currentStep } = this.state

    const prevStep = () => this.setState({ currentStep: currentStep - 1 })
    const nextStep = (i) => {
      let newStep = i !== undefined ? i : currentStep + 1
      if(i === 0 || stepCompleted[newStep - 1]){
        this.setState({ currentStep: newStep })
      }
    }
    const isLastStep = currentStep === progressSteps.length - 1

    const prevButton = <Button disabled={currentStep === 0} variant="contained" color="" className="mr-4" onClick={() => prevStep()}>Retour</Button>
    const nextButton = <Button variant="contained" color="primary" onClick={() => nextStep()}>Prochain</Button>
    const saveButton = <Button variant="contained" color="secondary" onClick={this.saveRoomToStore.bind(this)}>Sauver</Button>

    return <>
      <div className="w-full py-4 bg-light-grey">
        <div className="container mx-auto ">
          <Stepper nonLinear activeStep={currentStep} style={{ backgroundColor: "transparent" }}>
            {progressSteps.map((s, i) => 
              <Step key={s}>
                <StepButton
                  completed={stepCompleted[i]}
                  onClick={() => nextStep(i)}
                >
                  <StepLabel $active={'font-bold'} classes={{
                    active: '',
                    completed: 'font-bold',
                  }}>
                    {s}
                  </StepLabel>
                </StepButton>
              </Step>
            )}
          </Stepper>
        </div>
      </div>
      <div className="container mx-auto flex justify-end my-4">
        <div className="mr-4">
          { prevButton }
        </div>
        { isLastStep ? saveButton : nextButton }
      </div>
    </>
  }
  render() {
    const { room } = tmpRoom
    const { gotSteps, availableSteps, currentStep } = this.state
    const steps = [
      <ConstructionStepsForm room={room} steps={availableSteps} />,
      <Paper className="container mx-auto w-full">
        <RoomSummary savable={true} room={room} noResult={<NoResult
        title="Pas d'étapes sélectionnées" description={
          <div className="underline" onClick={() => this.setState({currentStep: 0 })}> Ajoutez des étapes ici</div>
        } />}/>
      </Paper>,
      this.renderSummary()
    ]
    return (
      <React.Fragment>
        <div>
          {this.renderHeader()}
        </div>
        { this.renderProgress() }
        {gotSteps ?
          steps[currentStep]
          : <Loader withContainer />
        }
      </React.Fragment>
    )
  }
}

const ProjectRoomEditReactive = view(ProjectRoomEdit)
const ProjectWithSteps = (props) => {
  // currently subsctracting 1 because we added 1
  // when saving a proj (see ProjectInfoForm)
  const provider_id = store.project.provider_id - 1
  const { getStepsFor, providers } = useSpreadsheetSteps()
  return <ProjectRoomEditReactive {...props} getSteps={getStepsFor} provider={providers[provider_id]} />
}
export default view(ProjectWithSteps)