import React, { PureComponent } from 'react'
import AppBar from '@material-ui/core/AppBar';
import { Link } from 'react-router-dom'
import { ArrowBack } from '@material-ui/icons';
const logo = require('../images/logo.png').default

const titler = (title, { Elem = 'h1', align = 'center' }) => {
  if(typeof title === 'object'){
    const { top, bottom } = title
    if (!title.hasOwnProperty('top') && !title.hasOwnProperty('bottom')){
      return title
    }
    return <div className={`text-${align}`}>
      <div>
        {top}
      </div>
      <Elem className="mt-0 mb-0">
        {bottom}
      </Elem>
    </div>
  }
  else if(typeof title === 'string'){
    return <Elem className="">{title}</Elem>
  }
}
const logoImg = <img style={{ maxWidth: "50px", bordera: "5px solid white" }} src={logo} alt="logo" />
export default class MenuBar extends PureComponent {
  render() {
    const { left, title, backTo, right } = this.props
    const backButton = backTo && <Link to={backTo}><ArrowBack /></Link>
    return (
      <AppBar position="relative" className="no-print">
        <div className="container py-2 mx-auto grid grid-cols-2 sm:grid-cols-3 w-full items-center justify-between">
          <div>
            {left || backButton}
          </div>
          <div className="hidden sm:flex flex-grow justify-center">
            <a href="/">
              {logoImg}
            </a>
          </div>
          {right && <div className="flex justify-end">
            {titler(right, {Elem:'h4', align: 'right' })}
          </div>}
        </div>
      </AppBar>
    )
  }
}