import { useReducer, useMemo } from 'react';
import store from '../newProjectStore';
import useSpreadsheetSteps from '../../steps/useSpreadsheetSteps';
import fabricateContext from '../../utils/fabricateContext';


const reducer = (state, { type, payload }) => {
  const orderSteps = (steps, { orderBy = payload.categories, key = 'category' } = {}) => steps.sort(function (a, b) {
    return orderBy.indexOf(a[key]) - orderBy.indexOf(b[key])
  });
  switch (type) {
    case 'EDIT_NOTE':
      return {
        ...state,
        dirty: true,
        note: payload.value,
      }
    case 'SELECT_STEP':
      return {
        ...state,
        selected: [...state.selected, {...payload.step, index: Math.floor(Math.random()*10000)}]
      }
    case 'UNSELECT_STEP':
      return {
        ...state,
        selected: state.selected.filter((s) => s.id !== payload.step.id),
      }
    case 'ADD_SELECTION':
      return {
        ...state,
        dirty: true,
        steps: orderSteps([...state.steps, ...state.selected.map(s => ({
          quantity: payload.quantity,
          ...s,
        }))]),
        selected: [],
      }
    case 'ADD_STEP':
      return {
        ...state,
        dirty: true,
        selected: [...state.selected, { ...payload.step, index: Math.floor(Math.random() * 10000)}],
      };
    case 'EDIT_STEP':
      return {
        ...state,
        dirty: true,
        steps: state.steps.map(s => {
          if (s.index !== payload.index) {
            return s;
          }
          return payload;
        }),
      };
    case 'REMOVE_STEP':
      return {
        ...state,
        dirty: true,
        steps: state.steps.filter(s => {
          return s.index !== payload.index;
        }),
      };

    default:
      return state;
  }
};

const useRoomSteps_ = ({ initRoom }) => {
  const { getStepsFor } = useSpreadsheetSteps();
  const { stepList, categories  } = useMemo(() => {
    const { provider_name } = store.project;
    return getStepsFor(provider_name, initRoom.roomType);
  }, [initRoom.roomType, getStepsFor]);
  

  const [room, dispatch] = useReducer(reducer, {
    ...initRoom,
    dirty: false,
    selected: [],
  });
  // currently subsctracting 1 because we added 1
  // when saving a proj (see ProjectInfoForm)
  return {
    dispatch,
    stepList,
    room,
    selected: room.selected,
    categories,
  };
};

export const {
  Provider: RoomProvider,
  Consumer: RoomConsumer,
  useContext: useRoomSteps,
} = fabricateContext(useRoomSteps_)
