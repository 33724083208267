import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import store from '../../newProjectStore';
import { PIECE_HUMIDE, PIECE_SECHE } from './index';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import useFeedback from '../../../ui/feedback'

function MoreButton({ room }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const isNameable = [PIECE_HUMIDE, PIECE_SECHE].includes(room.roomType)
  const feedback = useFeedback()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const remove = () => {
    store.removeRoom(room)
    feedback(`${room.name} effacée`, 'warning')
    handleClose()
  };

  const duplicate = () => {
    store.duplicateRoom(room)
    feedback(`${room.name} dupliquée`, 'success')
    handleClose()
  };

  return (
    <div>
      <MoreVertIcon onClick={handleClick} fontSize="small" />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {}}>
          <Link
            to={routes.projects.show(store.project.slug).rooms.edit(room.slug)}
          >Éditer
          </Link>
        </MenuItem>
        <MenuItem onClick={remove}>
          Effacer
        </MenuItem>
        {isNameable && <MenuItem onClick={duplicate}>
          Dupliquer
        </MenuItem>}
      </Menu>
    </div>
  );
}

export default MoreButton