import credentials from '../config/credentials.json'

const API_KEY ='AIzaSyDgxKUNJf_L3LVGru6VZwVdKa_zwwPjv6g'
const SHEET_ID = '1n1YLh2pXO4fHZ5QGTJLkIxB4XNxKyvJ1jWFTG1LDSlM'
const SCOPES = "https://www.googleapis.com/auth/spreadsheets.readonly";
export default {
  apiKey: API_KEY,
  clientSecret: 'Nn-gBk-bQoFEJjZjkPlIaBv9',
  discoveryDocs:
    ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
  spreadsheetId: SHEET_ID,
  clientId: '409424531782-pkil6rbp59qovt53fh93umc37mfi86p5.apps.googleusercontent.com',
  scopes: SCOPES,
};