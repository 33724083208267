import React, { useState } from 'react'
import { view } from 'react-easy-state'
import { Chip, Avatar } from '@material-ui/core';
import { Done } from '@material-ui/icons'

import styles from './ConstructionStepsList.module.css'
import './ConstructionStepsForm.css';
import { getFilteredStepsAndMeta, groupStepsByCategory } from '../utils/sortProject'
import SearchInput from '../ui/SearchInput';
import ConstructionStepsTable from './ConstructionStepTable';
import NoResult from '../ui/NoResult';

function TagFilters({ tags, setSelectedTags, selectedTags }){
  return <div>
    {tags.map(t => {
      const isSelected = selectedTags.find(t_ => t_.name === t.name)
      const onClick = () => {
        if (isSelected) {
          setSelectedTags(selectedTags.filter(t_ => t_.name !== t.name))
        }
        else {
          setSelectedTags([t, ...selectedTags])
        }
      }

      // TODO: [EST] better initials
      let initials = t.name.match(/\b\w/g) || [];
      initials = ((initials.shift() || '') + (initials.pop() || ''));
      return <Chip
        key={t.name}
        avatar={<Avatar>{initials}</Avatar>}
        label={t.name}
        clickable
        color={isSelected ? "secondary" : "default"}
        onClick={onClick}
        deleteIcon={<Done />}
        variant="outlined"
      />
    }
    )}
  </div>
}

function ConstructionStepsForm({ room, steps }) {
  
  const [searchValue, setSearch] = useState("")
  const [selectedTags, setSelectedTags] = useState([])

  const { tags, categories, filteredGroupedSteps } = getFilteredStepsAndMeta(steps, {
    query: searchValue,
    tags: selectedTags,
    roomType: room.roomType,
  })

  const [selectedCategory, selectCategory] = useState(categories[0])

  const searchBar = <SearchInput className={["pr-4", styles.searchContainer].join(' ')} value={searchValue} onChange={({target: { value } }) => setSearch(value)} />

  const selectedGroupedSteps = groupStepsByCategory(room.steps).groupedSteps

  const sideBar = <React.Fragment>
    {categories.map(k => {
      const count = selectedGroupedSteps[k] ? selectedGroupedSteps[k].length : 0
      return <div 
        key={k}
        className={[
          selectedCategory === k ? styles.selected : '',
          styles.categoryContainer,
        ].join(' ')} 
        onClick={() => selectCategory(k)}>
          <div className="text-lg font-bold mb-2">{k}</div>
          <div className="opacity-75">{`${count} séléctionné${count > 1 ? 's' : ''}`}</div>
        </div>
    })}
    </React.Fragment>

    const renderStepsByCategory = () => (
      <div className={["container mx-auto flex-col w-full", styles.grid].join(' ')}>
        { searchBar }
        {
          !categories.length ? <NoResult title="Désolé..." description="Aucune étape pour votre recherche" />
          : <React.Fragment>
            <div className="overflow-y-auto">
              <div className="pr-4 overflow-y-auto">{sideBar}</div>
            </div>
            <div className="overflow-y-auto">
              <div className="overflow-y-auto px-2 py-1">{<ConstructionStepsTable steps={filteredGroupedSteps[selectedCategory]} room={room} />}</div>
            </div>
          </React.Fragment>
        }
      </div>
    )
    const isMobile = window.innerWidth < 768
    const renderSearchResults = () => {
      const flattenSteps = Object.entries(filteredGroupedSteps).reduce((prev, [cat, steps]) => [...prev, ...steps], [])
      return <div className="container mx-auto w-full">
        { searchBar }
        {
          Object.entries(filteredGroupedSteps).map(([cat, steps]) => (
            steps.length > 0 && (
              <>
                <div className="w-full bg-yellow py-1 pl-1">
                  {cat}
                </div>
                <ConstructionStepsTable steps={steps} room={room} />
              </>)
          ))
        }
      </div>
    }

  // this weird html structure is needed to have the scrollable grid :/ 
  // https://codepen.io/walidvb/pen/RmqGdE
  return (
    (isMobile || searchValue.length) ? renderSearchResults() : renderStepsByCategory()
  )
}

export default view(ConstructionStepsForm)
