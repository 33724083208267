import React, { useState } from 'react'
import { Button } from '@material-ui/core';
import MyDialog from '../ui/Dialog';

function ButtonWithConfirmation({
  buttonIcon,
  buttonText,
  variant = 'contained',
  color = 'primary',
  dialogProps,
  className
}) {
  const [open, setOpen] = useState(false)
  const willOpen = () => setOpen(true)
  return (
    <div className={className}>
      {buttonIcon ? <div className="cursor-pointer" onClick={willOpen}>{buttonIcon}</div> : 
        <Button variant={variant} color={color} onClick={willOpen}>{ buttonText}</Button>
      }
      <MyDialog {...dialogProps} open={open} onCancel={() => setOpen(false)}/>
    </div>
  )
}

export default ButtonWithConfirmation
