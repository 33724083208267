/**
 * This store is used when editing a project
 */

import { store } from 'react-easy-state'
import { observe } from '@nx-js/observer-util'

const tmpRoom = store({
  room: {
    steps: []
  }
})

const computeRoomStepState = () => {
  const stepCompleted = [Boolean(tmpRoom.room.steps.length)]
  stepCompleted.push(stepCompleted[0] && Boolean(!tmpRoom.room.steps.find(s => !s.quantity)))
  stepCompleted.push(tmpRoom.room.note && tmpRoom.room.note.length)
  tmpRoom.room.stepCompleted = stepCompleted
}

observe((e) => {
  console.log('saving tmpRoom', tmpRoom, e)
  computeRoomStepState()
})

export default tmpRoom