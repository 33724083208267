import { store } from 'react-easy-state'
import { observe } from '@nx-js/observer-util'
import routes from '../routes'
import slugify from 'slugify';
import projectController from '../utils/projectController';
import projectsAPI from '../utils/projectsAPI';

const defaultRoom = {
  steps: [],
  notes: '',
}

const makeSlugForRoom = (name) => {
  let slug, hasConflict, i = 0;
  do {
    slug = slugify(name)
    if(i){
      slug = `${slug}-${i}`
    }
    hasConflict = state.project.version.rooms.map(r => r.slug).includes(slug)
    i++
  }
  while(hasConflict)
  return slug
}

const state = store({
  errors: {},
  project: null,
  locked: false,
  getVersion: (versionId) => {
    state.getProject(state.project.id, versionId)
  },
  
  // FRONT-END ACTIONS
  addRoom: (roomDetails, history) => {
    const slug = makeSlugForRoom(roomDetails.name)
    
    state.project.version.rooms.push({
      ...defaultRoom,
      ...roomDetails,
      slug,
    })
    // TODO: replace rather than push
    history.push(routes.projects.show(state.project.slug).rooms.edit(slug), {
      lastAction: {
        action: `${roomDetails.name} ajoutée`,
        status: 'success',
      }
    })
  },
  duplicateRoom: (roomDetails, history) => {
    const newName = `${roomDetails.name} copie`
    
    state.project.version.rooms = state.project.version.rooms.reduce((acc, curr) => {
      if (curr !== roomDetails){
        return [...acc, curr]
      }
      const copy = {
        ...curr,
        name: newName,
        slug: makeSlugForRoom(newName),
      }
      return [...acc, curr, copy]
    }, [])
  },
  renameRoom: (room, name) => {
    state.project.version.rooms = state.project.version.rooms.map((r) => {
      if(room !== r){
        return r
      }
      return {
        ...r,
        name,
        slug: makeSlugForRoom(name),
      }
    }
    )
  },
  removeRoom: (room) => {
    const {name} = room
    state.project.version.rooms = state.project.version.rooms.filter(r => r !== room, {
      lastAction: {
        status: 'success',
        action: `Pièce ${name} effacée`
      }
    })
  },
  saveRoom: (room, history) => {
    // QUESTION: why doesn't forEach(r => r = room) work
    state.project.version.rooms = state.project.version.rooms.map((r) => 
    (r.slug === room.slug) ? room : r
    )

    state.project.status = 'draft'
    history.push(routes.projects.show(state.project.slug).rooms.index, {
      lastAction: {
        status: 'success',
        action: `${room.name} saved`
      }
    })
  },
  save: async () => {
    const project = state.project
    state.project.status = 'pending'
    const saved = await projectController.save(project, false)
    console.log(saved)
    state.project = saved
    
  }
})

// const debouncedSave = debounce((data) => {
//   console.log('deb', data)
//   projectsAPI.saveOrCreate(data)
// }, 1000, true)

observe(async (e) => {
  console.log('state changed')
  if(state.project){
    // debouncedSave is only called if the
    // projectsAPI.saveOrCreate is also called...
    // debouncedSave(state.project)
    try{
      await projectsAPI.saveOrCreate(state.project, { handleError: false })
      state.project.status = 'synced'
    } catch(err) {
      console.log(err)
      if(!err.response){
        state.project.status = 'unsynced'
      }
    } finally{
    }
    // // the status is changed by the API
    // // but should be set to unsynced if the project is changed
    // // there is no way to see what changed(the status or otherwise)
    // // so when the incoming project(from the API) is returned as 'synced'
    // // we compare the local and incoming version. If they're the same
    // if(status === 'synced' || status === 'draft'){
    //   const {status: foo,updated_at: foo1,  
    //     ...localStoredDetails} = localProjectAPI.get(state.project.slug)
    //   const {status: bar,updated_at: bar1,  
    //     ...incomingProjectDetails} = state.project
    //   console.log(status, diff(localStoredDetails, incomingProjectDetails), equal(localStoredDetails, incomingProjectDetails))
    //   if (equal(localStoredDetails, incomingProjectDetails)){
    //   }
    //   else{
    //     status = 'draft'
    //   }
    // }
    // state.project.status = status
    // localProjectAPI.save({
    //   ...state.project,
    //   status,
    // })
  }
})

export default state;