import React, { useState, useEffect, Fragment } from 'react'
import { view } from 'react-easy-state'
import { Button } from '@material-ui/core';
import moment from 'moment'
import { Print, Edit } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import routes from '../routes';
import Loader from '../ui/Loader';
import MenuBar from '../ui/MenuBar';
import TitleWithBar from '../ui/TitleWithBar';
import ButtonLink from '../ui/ButtonLink';
import projectController from '../utils/projectController';
import SyncStatus from '../ui/SyncStatus';
import projectsAPI from '../utils/projectsAPI';
import CardItem from '../ui/CardItem';
import DeleteProject from './DeleteProject';
import { useSnackbar } from 'notistack';

function ProjectVersionIndex({ history, location, match: { params: { projectSlug }}}) {
  const [ project, setProject] = useState()
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    (async function(){
      try{
        const proj = await projectsAPI.get(projectSlug)
        setProject(proj)
      }
      catch(err){
        enqueueSnackbar(`Le projet ${projectSlug} n'existe pas`, {
          variant: 'warning',
          autoHideDuration: 2000,
        })
        history.push('/projects')
      }
    })()
  }, [projectSlug, setProject])

  if(!project){
    return <Loader withContainer={true}/>
  }
  const versionCard = (v) => {
    const meta = {
      "Pièces": v.rooms_count,
      CHF: <span className="font-bold">{v.price && v.price.toLocaleString()}</span>,
    }
    
    const path = v.locked ? 
      routes.projects.show(project.slug).versions.quote(v.version_reference) 
      : routes.projects.show(project.slug).rooms.index

    const showStatus = project.status !== ''
    return <Link to={path} className="mb-4 sm:mb-0">
      {!v.locked && <div className="text-blue mb-2 font-sm font-bold">Version en cours</div>}
      <div className={!v.locked ? 'draft-version' : ''}>
        <CardItem>
          <div className="pb-2 mb-4 flex justify-between flex-row">
            <div> Devis {moment(v.updated_at).format("DD/MM/YYYY")}</div>
            {v.locked ? <Print /> : <Edit />}
          </div> 
          {showStatus && <div className="pt-2 mt-2 flex justify-end">
              <SyncStatus project={project} />
          </div>}
          {v.price && 
            <div className="flex justify-end text-blue">
              CHF {v.price.toLocaleString()}
            </div>
          }
        </CardItem>
      </div>
    </Link>
  }

  const draftVersion = project.version
  const hasDraft = !draftVersion?.locked
  
  const nextUrl = routes.projects.show(project.slug).rooms.index
  const editDraftButton = <ButtonLink color="secondary" to={nextUrl}> 
    Éditer brouillon
  </ButtonLink>

  const createNewVersion = () => {
    const projWithNewVersion = {
      ...project,
      version: {
        ...project.version,
        locked: false,
      }
    }
    projectController.create(projWithNewVersion, history)
  }
  const newVersionButton = <Button variant="contained" color="secondary" onClick={createNewVersion}>
    Éditer Devis
  </Button>
  const rightButton = hasDraft ? editDraftButton : newVersionButton
  return (
    <Fragment>
      <MenuBar backTo={routes.projects.index} 
        right={<div className="flex items-center">
          <DeleteProject project={project} className="mr-2" />
          {rightButton}
        </div>
          }
      />
      <div className="flex-grow">
        <div className="container mx-auto mt-8">
          <TitleWithBar title={project.title} />
          <div className="grid-3 mt-8 items-end ">
            {project.versions.map(versionCard)}
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default view(ProjectVersionIndex)