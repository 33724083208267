import React from 'react'
import { Link } from 'react-router-dom';

const Page404 = () => {
  return <div className="container text-center mx-auto py-8 font-2xl">
    <div className="text-lg">404</div>
    <Link to="/">Retourner aux projets</Link>
  </div>
}

export default Page404