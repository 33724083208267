import { Button, InputAdornment, TextField } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NoteInput from '../../../ui/NoteInput';
import { computeStepPrice } from '../../../utils/pricing';
import { useRoomSteps } from '../useRoomSteps';
import styles from './Step.module.css';


const StepPlaceholder = ({ onClick }) => {
  return <div 
    onClick={onClick}
    className="py-8 bg-white cursor-pointer hover:bg-light-grey hover:border-dark-white transition-all transition border-dashed text-dark-grey border-light-grey border-4 content-center flex justify-center items-center">
    + Ajouter une étape personnalisée
  </div>
}

const Step = ({ canAdd, canRemove, onUpdateStep, step, onRemoveStep }) => {
  const { dispatch, selected, categories } = useRoomSteps()
  const [isOpen, setIsOpen] = useState(canRemove)
  const [description, setDescription] = useState(step.description)
  const [quantityString, setQuantity] = useState(step.quantity)
  const quantity = useMemo(() => (!quantityString || quantityString === '') ? 0 : parseFloat(quantityString), [quantityString])
  const [priceString, setPrice] = useState(step.price)
  const price = useMemo(() => priceString === '' ? 0 : parseFloat(priceString), [priceString])
  const [note, setNote] = useState(step.note)

  const [wasJustAdded, setIsJustAdded] = useState(false)
  const total = computeStepPrice({ ...step, quantity, price })
  const [showForm, setShowForm] = useState(!canAdd)
  
  // selected might be undefined on review page
  const isSelected = selected?.find(s => s.id === step.id)
  useEffect(() => {
    if (canRemove || (canAdd && (quantity > 0 || (note && note.length) || (price && price > 0)))){
      setIsOpen(true)
    }
  }, [canAdd, quantity, note, price, canRemove])

  // this is used to prevent calling the dispatch callbacks right on mount
  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    if(canAdd){
      return
    }
    if(firstRender){
      setFirstRender(false)
      return
    }
    if (onUpdateStep){
      onUpdateStep({ note, quantity, description, price })
    }
    if(dispatch){
      dispatch({
        type: 'EDIT_STEP',
        payload: {
          ...step,
          quantity,
          note,
          price,
          description,
        }
      })
    }
  }, [note, quantity, price, description, onUpdateStep, dispatch])

  const reset = () => {
    setNote('')
    setQuantity('')
    setPrice(step.price)
    setDescription(step.description)
    setIsOpen(false)
    setShowForm(false)
  }

  const onAdd = () => {
    // we hide the form as we don't
    // edit state.selected step, but
    // only edit locally 
    setShowForm(false)
    // setIsJustAdded(true)
    // should attach to animation done 
    // setTimeout(reset, 300)
    // setTimeout(() => setIsJustAdded(false), 1000)
    dispatch({
      type: 'ADD_STEP',
      payload: {
        categories,
        step: {
          ...step,
          quantity,
          description,
          note,
          price,
        }
      }
    })
  }

  const remove = () => {
    if (dispatch) {
      dispatch({
        type: 'REMOVE_STEP',
        payload: step,
      })
    }
    // legacy from when we displayed
    // the steps in the project page
    // which was linked to the store via
    // react-easy-state. We removed that view
    // and are trying to get rid of r-e-s
    if (onRemoveStep) {
      onRemoveStep()
    }
  }

  const selectStep = () => {
    if(dispatch && canAdd){
      dispatch({
        type: isSelected ? 'UNSELECT_STEP' : 'SELECT_STEP',
        payload: { step },
      })
    }
  }

  const onChange = useMemo(() => ({
    quantity: ({ target: { value } }) => setQuantity(value),
  }), [setQuantity])
  const unitDisplayed = step.unit
  const qtyInput = <div className="flex-shrink">
    <TextField
      id="quantity"
      name="quantity"
      defaultValue={quantityString}
      // value={quantityString === '' ? quantityString : undefined}
      onChange={onChange.quantity}
      // onFocus={useCallback(({ target }) => target.select(), [])}
      type="number"
      width="18ch"
      margin="none"
      InputProps={{
        style: {
          fontSize: '1rem',
        },
        inputProps: {
          inputMode: 'decimal',
        },
        endAdornment: <InputAdornment position="end">{unitDisplayed}</InputAdornment>
      }}
    />
  </div>
  const noteInput = <div className="w-3/4">
    <NoteInput 
      defaultValue={note}
      placeholder={'Ajouter une note...'}
      onChange={useCallback((note) => setNote(note), [setNote])}
    />
  </div>

  const priceInput = <div className="flex-shrink">
    <TextField
      type="number"
      id="price"
      rows={1}
      rowsMax={1}
      margin="none"
      defaultValue={priceString}
      // value={priceString === '' ? priceString : undefined}
      onChange={useCallback(({ target: { value } }) => setPrice(value), [setPrice]) }
      width="18ch"
      placeholder=""
      InputProps={{
        style: {
          fontSize: '1rem',
        },
        inputProps: {
          inputMode: 'decimal',
          minWidth: '18ch',
        },
        className:"",
        startAdornment: <InputAdornment position="start">CHF</InputAdornment>
      }}
    />
  </div>
  
  const totalComp = <div className="text-blue flex font-bold justify-end" style={{minWidth: '12ch'}}>
    CHF {total && total.toLocaleString()}
  </div>
  const enabled = [price, description].reduce((prev, curr) => curr && curr !== '' && prev, true)
  const actions = (
    <div className="flex flex-grow justify-end">
      { canAdd &&
        (!isSelected ? <Button
          variant="contained"
          color="secondary"
          disabled={!enabled}
          onClick={onAdd}
      > Ajouter </Button> : <Button
        variant="contained"
        color="cancel"
        disabled={!enabled}
        onClick={selectStep}
      > Retirer </Button>)
      }
      {
        canRemove && <Button
          className="self-end "
          variant="container"
          color="primary"
          onClick={remove}
        > Effacer </Button>
      }
    </div>
  )
  const onLongPress = (evt) => {
    setShowForm(true)

    evt.stopPropagation()
    if(!isSelected){
      return
    }
    dispatch({
      type: 'UNSELECT_STEP',
      payload: { step },
    })
  }

  console.log('rendering step')

  if(!showForm){
    if(!step.description){
       return <StepPlaceholder onClick={onLongPress} />
    }
    return <div
      onClick={selectStep}
      className={`px-4 block py-4 relative rounded-md bg-light-grey transition ${canAdd && isSelected && styles.selected} ${wasJustAdded && styles.added} ${isOpen ? styles.stepOpen : styles.stepClosed}`}
      // className={` py-6 relative rounded-d border-b-4 border-light-grey transition ${canAdd && isSelected && styles.selected} ${wasJustAdded && styles.added} ${isOpen ? styles.stepOpen : styles.stepClosed}`}
    >
      {description}
      <div onClick={onLongPress} className="color-icon absolute right-0 top-0 p-2">
        <MoreVert fontSize={'inherit'} />
      </div>
    </div>
  }
  return <div 
    className={[`px-6 py-6 relative rounded-md bg-light-grey transition`,
      canAdd && isSelected && styles.selected,
      wasJustAdded && styles.added,
      isOpen ? styles.stepOpen : styles.stepClosed,
    ].filter(Boolean).join(' ')}
  >
    <div>
      <div className="sm:flex items-start justify-between">
        <div className="flex-grow sm:mr-6">
          <div style={{'--blue': 'var(--black, #000)'}} >
            <NoteInput
              onChange={setDescription}
              defaultValue={description}
              id="description"
              placeholder="Description de l'étape..."
            />
          </div>
          <div>
            {noteInput}
          </div>
        </div>
          <div className="flex items-center mt-6 sm:mt-0 mb-4 sm:mb-4" >
            {qtyInput}
            <div className="mx-2 text-gray-500">
              x
            </div>
            {priceInput}
        </div>
      </div>
      <div className="flex">
        {totalComp}
        {actions}
      </div>
    </div>
  </div>
}

export default React.memo(Step)