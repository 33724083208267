import { Button } from '@material-ui/core';
import { ArrowBack, ShoppingCartOutlined } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { view } from 'react-easy-state';
import routes from '../../routes';
import MyCountUp from '../../ui/CountUp';
import MenuBar from '../../ui/MenuBar';
import { computeRoomTotal } from '../../utils/pricing';
import { groupStepsByCategory } from '../../utils/sortProject';
import ButtonWithConfirmation from '../ButtonWithConfirmation';
import store from '../newProjectStore';
import Step from './Step';
import { RoomProvider, RoomConsumer, useRoomSteps } from "./useRoomSteps";
import styles from './Step/Step.module.css';
import StepList from './StepList';
import styled from 'styled-components';
import BumpingCart from '../../ui/BumpingCart';
import { SelectedToolbar } from './SelectedToolbar';
import NoteInput from '../../ui/NoteInput';



const saveButton = (room, history) => (
  <Button
    variant="contained"
    color="secondary"
    disabled={!room.dirty}
    onClick={() => store.saveRoom(room, history)}>
    Sauver
  </Button>
)


const RoomHeader = ({ history, room, useIsOpen }) => {
  const navigateBack = () => history.push(routes.projects.show(store.project.slug).rooms.index)
  const [isOpen, setIsOpen] = useIsOpen
  if(isOpen){
    return <MenuBar
      right={<Button
        variant="contained"
        color="primary"
        onClick={() => setIsOpen(false)}>
        Terminer
  </Button>}
    />
  }
  const back = room.dirty ? (
    <ButtonWithConfirmation
      buttonIcon={<ArrowBack />}
      dialogProps={{
        title: "Voulez-vous vraiment quitter cette page?",
        content: "Vos changements effectués ici seront perdus. Cette opération est irréversible",
        acceptText: "Quitter",
        cancelText: "Annuler",
        onAccept: navigateBack,
      }}
    />
  ) : (
      <ArrowBack className={'cursor-pointer'} onClick={navigateBack} />
  )
  
  return <MenuBar 
    left={back}
    right={saveButton(room, history)}
  />
}

const useInitRoom = (roomSlug) => {
  const room = store.project.version.rooms.find((r) => r.slug === roomSlug)
  return room
}

const RoomSteps = ({ room: { name, steps, roomType }, dispatch }) => {
  if(!steps.length){
    return <div className="flex-col pt-24 text-center items-center h-full">
      <div className="text-6xl">
        <ShoppingCartOutlined fontSize={"inherit"} className="opacity-25 mb-4" />
      </div>
      <div className="text-gray-600">
        Ajoutez des travaux pour {name}
      </div>
    </div>
  }
  const { groupedSteps } = groupStepsByCategory(steps)
  return <div>
    {Object.entries(groupedSteps).map(([category, steps], index) => {
      if (!steps.length) {
        return null
      }

      return <div className="relative">
        <div className="w-full border-b-2 border-yellow py-1 pl-1 z-10" style={{ top: '50px' }}>{category}</div>
        <div>
          {steps.map((step) => <Step 
            key={`${step.id}-${index}`} 
            step={step} 
            dispatch={dispatch}
            canRemove
          />)}
        </div>
      </div>
    })}
  </div>
}


const AnimButton = styled(Button)`
  animation: fade-in .3s .15s ease-in-out forwards;
  opacity: 0;
  @keyframes fade-in{
    from{opacity: 0; transform: translateY(3px);}
    to{opacity: 1; transform: translateY(0px);}
  }
`

const RoomFooter = ({ room, isOpen, setIsOpen, children }) => {
  const classes = isOpen ? `${styles.panelClose} -mx-px sm:-mx-px border-2 border-blue ` : `${styles.panelOpen}`
  return <>
    <div 
      className={` bg-white z-30 sm:w-full container fixed rounded-tl-xl rounded-tr-xl ${classes} ${styles.panel}`}
    >
      {!isOpen && <AnimButton
        onClick={() => setIsOpen(true)}
        variant="contained"
        className="w-full mt-2"
        color="secondary"
      >
        + Ajouter des étapes
      </AnimButton>}
      <div className="sm:px-4 pb-4">
        {children}
      </div>
    </div>
    <SelectedToolbar />
  </>
}

const RoomNote = view(() => {
  const { room, dispatch } = useRoomSteps()
  const onChange = (value) => {
    dispatch({
      type: 'EDIT_NOTE',
      payload: {
        value
      },
    })
  }
  return <div className="bg-light-grey rounded-xl py-6 px-4 my-6 sm:mb-16 sm:mt-8">
    <div className="text-blue mb-2">
      Notes
      </div>
    <NoteInput
      defaultValue={room.note}
      onChange={onChange}
    />
  </div>
})

const RoomEdit = view(({ history, match: { params: { id: roomSlug } } }) => {
  const initRoom = useInitRoom(roomSlug)
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(initRoom.steps.length === 0)
  }, [initRoom.steps])

  return <RoomProvider initRoom={initRoom} >
    <RoomConsumer>
     { ({ room, dispatch, stepList}) => (
       <>
       <div className="sticky top-0 bg-white z-20">
            <RoomHeader history={history} room={room} useIsOpen={[isOpen, setIsOpen]}/> 
        <div
          className="mt-6 sm:mt-16 sm:mb-4 cursor-pointer container mx-auto"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="text-xl border-b-4 border-yellow flex justify-between">
            <div className="text-xl">
              {room.name}
            </div>
            <div class="flex">
              <div className="hidden sm:flex text-blue items-center text-xl font-bold">
                CHF <MyCountUp end={computeRoomTotal(room)} />
              </div>
              <BumpingCart count={room.steps.length} />
            </div>
          </div>
          <div className="flex sm:hidden text-blue items-center text-xl font-bold">
            CHF <MyCountUp end={computeRoomTotal(room)} />
          </div>
        </div>
      </div>
      <div className="container mx-auto pt-2 sm:pt-8">
        <RoomNote />
        <div className="pb-16">
          <RoomSteps room={room} dispatch={dispatch} />
        </div>
        <RoomFooter room={room}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        >
          {isOpen && <>
            <StepList steps={stepList} dispatch={dispatch} />
          </>
          }
        </RoomFooter>
      </div>
      </>
     )}
    </RoomConsumer>
  </RoomProvider>
})

export default RoomEdit