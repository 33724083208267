import { ControlPoint } from '@material-ui/icons';
import React, { useState } from 'react';
import { view } from 'react-easy-state';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import routes from '../../../routes';
import { computeRoomTotal } from '../../../utils/pricing';
import store from '../../newProjectStore';
import EditableName from './EditableName';
import MoreButton from './MoreButton';


export const PIECE_HUMIDE = "Pièce Humide"
export const PIECE_SECHE = "Pièce Sèche"

const Row = styled.div`
  :nth-child(2n) > .row{
    background-color: var(--bg-grey);
  }
  :nth-child(2n+1) > .row{
    background-color: var(--white);
  }
  transition: all .15s ease-in-out;
  :nth-child(2n+1) .room-details{
    background-color: var(--bg-grey);
  }
  :nth-child(2n) .room-details{
    background-color: var(--white);
  }
`
const ROW_CLASSES = "rounded-md justify-between py-2 row"



const RoomListItem = view(({ requireName, onCreate, roomType, odd, room: r }) => {
  const hasRoom = Boolean(r)// && r.steps.length
  // should have its own component
  const [name, setName] = useState(r && r.name)
  if (!hasRoom) {
    return <NewRoom {...{requireName, onCreate, name, setName, roomType}} />
  }
  return <ExistingRoom {...{r, requireName}} />
});

export default RoomListItem

const ExistingRoom = view(({ r, requireName }) => {
  const project = store.project

  return <Row>
    <Link
      className={`flex w-full px-2 overflow-x-hidden ${ROW_CLASSES} cursor-pointer`}
      to={routes.projects.show(project.slug).rooms.edit(r.slug)}
    >
      <div className="flex-grow">
        <EditableName isOpen={requireName} name={r.name} onChangeName={(value) => store.renameRoom(r, value)} />
      </div>
      { <>
        <div className="mr-4 text-blue">
                CHF {computeRoomTotal(r).toLocaleString()}
        </div>
        <div className="text-dark-grey" onClick={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
        } }>
          <MoreButton room={r} />
        </div>
      </>}
    </Link>

  </Row>;
})

const NewRoom = view(({requireName, onCreate, name, setName, roomType}) => {
  return <Row odd onClick={() => !requireName && onCreate()} className={`flex cursor-pointer `}>
    <div className={`${ROW_CLASSES} relative text-dark-grey italic w-full px-2 flex`}>
      {requireName ? <EditableName
        isOpen={requireName}
        name={name}
        onChangeName={setName}
        roomType={roomType} />
        : roomType}
      <div
        className={`text-dark-grey opacity-75 ${requireName && name === '' && 'pointer-events-none absolute right-0'}`}
        onClick={() => {
          requireName && onCreate(name);
        } }
      >
        <ControlPoint fontSize="small" />
      </div>
    </div>
  </Row>;
})
