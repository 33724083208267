import React from 'react'
import { view } from 'react-easy-state'
import { Avatar } from '@material-ui/core';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import Check from '@material-ui/icons/Check'
import NoResult from '../ui/NoResult';

function ConstructionStepsTable({ room, steps = [] }) {
  
  
  if(!steps.length){
    return <NoResult description="Aucune étape pour votre recherche..." title="Désolé..."/>
  }

  
  const tags = (step) => <div className="flex">{step.tags.map(t => {
    let initials = t.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || ''))
    return <Avatar className="MuiChip-avatar" >{initials}</Avatar>
  })}
  </div>

  // as Expanded Panels
  // const [expanded, setExpanded] = React.useState(false);
  // const handleChange = panel => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };
  //
  // const summary = (step, existingStep) => <ExpansionPanelSummary>
  //   <span className={[
  //     existingStep ? 'line-through' : '',
  //     'flex-1'
  //     ].join(' ')}>
  //     {step.id} - {step.description}
  //   </span>
  //   {tags(step)}
  // </ExpansionPanelSummary>
  //
  //
  // const stepItem = (step) => {
  //   const existingStep = alreadyAddedStep(step)
  //   return <ExpansionPanel 
  //       key={step.id}
  //       expanded={expanded === step.id}
  //       onChange={handleChange(step.id)}
  //     >
  //     {summary(step, existingStep)}
  //     {expanded === step.id && details(step, existingStep) }
  //   </ExpansionPanel>
  // }
  //
  // const details = (step, existingStep) => <ExpansionPanelDetails>
  //   <StepForm room={room} step={existingStep || step}  onStepSaved={() => setExpanded()}/>
  // </ExpansionPanelDetails>

  const alreadyAddedStep = (step) => room.steps.find(s => s.id === step.id)

  const stepItem = (step, isChild) => {
    const hasStep = alreadyAddedStep(step)
    const children = (step.sequence && step.sequence.length) ? steps.filter(s => step.sequence.includes(s.id)) : []

    // REFACTOR: better performance for this por favor!
    const toggleStep = (step_) => {
      const children_ = (step_.sequence && step_.sequence.length) ? steps.filter(s => step_.sequence.includes(s.id)) : []
      const hasStep_ = alreadyAddedStep(step_)
      if (hasStep_){
        room.steps = room.steps.filter(s => s.id !== step_.id)
      }
      else{
        room.steps.push(step_)
      }
      children_.forEach(s => toggleStep(s))
    }

    const toggler = !hasStep ? <CheckBoxOutlineBlank className="opacity-50" /> : <Check color="primary" />
    return <React.Fragment key={`${step.id}-${isChild ? 'child' : ''}`}>
      <tr className={isChild ? 'text-brand-color' : ''} onClick={() => toggleStep(step)} key={`${step.id}-${isChild ? 'child' : ''}`}>
        <td className={`py-2 ${isChild ? 'pr-4' : 'pl-6'}`}>
          {!isChild && toggler}
        </td>
        <td>
          {isChild && toggler}
        </td>
        <td className="pr-2 py-2">
          {step.id}
        </td>
        <td className="pr-2 py-2">
          {step.description}
        </td>
        <td className="">
          {tags(step)}
        </td>
      </tr>
      {hasStep && children.map((c) => stepItem(c, true))}
    </React.Fragment>
  }
  return (
    <table className="striped-table">
      <tbody>
        {steps.map(s => stepItem(s, false))}
      </tbody>
    </table>
  )
}

export default view(ConstructionStepsTable)
