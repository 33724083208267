import slugify from "slugify";
import routes from "../routes";

// TODO: [UI] add js error handling
const projectLocalStorageKey = 'projects_in_progress'

const defaultVersion = {
  rooms: [],
  locked: false,
}
const defaultProject = {
  isLocalDraft: true,
  version: defaultVersion,
}

const localProjectsAPI = {
  index: () => getProjectsFromLocalStorage(),
  get: (projectSlug) =>  getProjectsFromLocalStorage(projectSlug),
  create: (project) => {
      const slug = slugify(project.internal_id).toLowerCase()
      return {
        ...defaultProject,
        ...project,
        created_at: new Date(),
        slug,
      }
  },
  createVersion: ({ version, ...project }) => {
    localProjectsAPI.save({
      ...project,
      version: {
        ...version,
        locked: false,
        version_reference: undefined,
        created_at: new Date(),
      }
    })
  },
  save: (project) => {
    writeProjectToLocalStorage(project)
  },
  getUnsynced: () => {
    const projects = getProjectsFromLocalStorage()
    return projects.filter(p => p.status === 'unsynced')
  },
  deleteDraft: (project, history) => {
    const { title } = project
    project = null
    localStorage.removeItem(projectLocalStorageKey)
    history.push(routes.projects.index, {
      lastAction: {
        status: 'success',
        action: `Brouillon ${title} effacé`
      }
    })
  }
}
export default localProjectsAPI


const writeProjectToLocalStorage = (project) => {
  // save projects by keys maybe instead of in array?
  const allProjJSON = localStorage.getItem(projectLocalStorageKey)
  const allProjs = JSON.parse(allProjJSON || "[]")
  let projectedWasLocallySaved = false
  let newAllProjs = allProjs.map(p => {
    if (p.slug === project.slug) {
      projectedWasLocallySaved = true
      return project
    }
    return p
  })
  if (!projectedWasLocallySaved) {
    newAllProjs = [project, ...newAllProjs]
  }
  localStorage.setItem(projectLocalStorageKey, JSON.stringify(newAllProjs))
}

const getProjectsFromLocalStorage = (projectSlug) => {
  const tmp = localStorage.getItem(projectLocalStorageKey)
  if (tmp) {
    const allProjects = JSON.parse(tmp)
    if (projectSlug) {
      return allProjects.find(p => p.slug === projectSlug)
    }
    return allProjects
  }
  if(!projectSlug){
    return []
  }
}