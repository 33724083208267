import React from 'react'
import { view } from 'react-easy-state'
import { Switch, Route } from 'react-router-dom'

import ProjectRoomNew from './ProjectRoomNew'
import ProjectRoomEdit from './ProjectRoomEdit'
import ProjectRoomIndex from './ProjectRoomIndex';

import routes from '../routes';
import RoomEdit from './room/RoomEdit';

function ProjectRooms() {
  return (
      <Switch>
        <Route path={routes.projects.show(":projectSlug").rooms.new} component={ProjectRoomNew} />
        <Route path={routes.projects.show(":projectSlug").rooms.edit(':id')} component={RoomEdit} />
        <Route path={routes.projects.show(":projectSlug").rooms.index} component={ProjectRoomIndex} />
        {/* <Route path={routes.projects.show(":projectSlug").rooms.edit(':id')} component={ProjectRoomEdit} /> */}
      </Switch>
  )
}

export default view(ProjectRooms)
