import React, { PureComponent } from 'react'
import logo from '../../images/logo_full.png'
import styles from './Quote.module.css'

export default class QuoteHeader extends PureComponent {
  render() {
    const companyInfo = {
      name: 'GRI – Group de Rénovations Immobilières SA',
      address: 'Avenue industrielle, 1 CH-1227 Carouge',
      tel: '022 347 84 84',
      fax: '022 347 84 85',
      email: 'info@groupderenovations.ch',
      website: 'https://www.groupderenovations.ch',
      vat: 'CHE 110.257.937',
    }

    const { name, address, tel, fax, email, website, vat } = companyInfo
    return (
      <React.Fragment>
        <div className="flex mb-6 my-6">
          <div className="flex-1">
            <img alt="logo" src={logo} className={styles.logo}/>
          </div>
          <div>
            <div className="text-uppercase">
              {name}
            </div>
            <div>
                {address}
            </div>
            <div>
              <span>
                téléphone: <a href={`tel:{tel}`}>{tel}</a>
              </span>
              &nbsp;&bull;&nbsp;
              <span>fax: {fax}</span>
            </div>
            <div>
              <span><a href={`mailto:${email}`}>{email}</a></span>
              &nbsp;&bull;&nbsp;
              <span><a href={`${website}`}>{website.replace(/https?:\/\//, '')}</a></span>
            </div>
          </div>
        </div>
        <div className="mb-4">
          {vat}
        </div>
      </React.Fragment>
    )
  }
}
