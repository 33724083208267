import React from 'react';
import ButtonWithConfirmation from './ButtonWithConfirmation';
import { DeleteOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import routes from '../routes';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import useFeedback from '../ui/feedback';

function DeleteProject({ className, project }) {
  const history = useHistory()
  const feedback = useFeedback()
  const runDelete = async (cb) => {
    console.log('deleting', project)
    try{
      await axios.delete(routes.api.projects.delete(project.slug))
      feedback(`${project.title} effacé`)
      history.replace(routes.projects.index)
    } catch(err){
      console.log(err)
      feedback(`Erreur: ${JSON.stringify(err)}`, 'warning')
    }
    cb()
  }
  return (
    <div className={[className].join(' ')}>
      <ButtonWithConfirmation
        className="mr-4"
        buttonText={<DeleteOutlined />}
        variant='primary'
        color="default"
        dialogProps={{
          title: `Voulez-vous vraiment effacer ${project.title}?`,
          content: "Cette action est irréversible",
          onAccept: runDelete,
        }}
      />
    </div>
  );
}

export default DeleteProject;