import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './print.css'
import App from './App';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import precache from './precache'
Sentry.init({ dsn: "https://20e73f01ea2c4100879f201db390e9b9@sentry.io/1472599" });

const rootEl = document.getElementById('root');
ReactDOM.render(<App />, rootEl);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    ReactDOM.render(
      <NextApp />,
      rootEl
    )
  })
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
// precache()