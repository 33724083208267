import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';

import { computeStepPrice } from '../utils/pricing.js'

import styles from './StepForm.module.css'

// used for CountUp
let previousTotal = 0

function StepForm({ room, step, onStepSaved }) {
  const [quantity, setQuantity] = useState(step.quantity)
  const [note, setNote] = useState(step.note)

  const submitStep = () => {
    if (!quantity) {
      return;
    }
    step.quantity = quantity
    step.note = note

    // TODO: [FAC] DRY this up!!
    if (step.sequence) {
      step.sequence.forEach(sId => {
        const child = room.steps.find(s => s.id === sId)
        if (child) {
          child.quantity = quantity
        }
      })
    }
    onStepSaved()
  }

  const removeStep = (step_) => {
    const index = room.steps.findIndex(s => s.id === step_.id)
    room.steps.splice(index, 1)
    if(step.sequence){
      step.sequence.forEach(sID => removeStep(step_))
    }
    onStepSaved()
  }
  const qtyInput = <TextField
    id="quantity"
    name="quantity"
    value={quantity}
    onChange={({ target: { value: quantity } }) => setQuantity(parseInt(quantity))}
    onFocus={({ target }) => target.select()}
    type="number"
    margin="none"
    required={true}
    error={!quantity}
    InputProps={{
      endAdornment: <InputAdornment position="end">{step.unit}</InputAdornment>
    }}
    className={styles.qtyInput}
  />

  const noteInput = <div className="note-container">
      <span style={{paddingTop: '8px'}}>Note:</span>
        <TextField
        type="textarea"
        id="note"
        multiline={true}
        rows={1}
        rowsMax={4}
        margin="none"
        autoFocus={true}
        value={note}
        onChange={({ target: { value: note }}) => setNote(note)}
        className="flex-1"
      />
  </div>

  const total = computeStepPrice({ ...step, quantity })
  previousTotal = isNaN(previousTotal) ? 0 : previousTotal

  const cols = {
    Quantité: qtyInput,
    "Prix unitaire": `CHF ${step.min_price}`,
    Total: `CHF ${total.toLocaleString()}`
  }
  const html = (
    <div className="w-full">
      <div className="flex light-bottom-border pb-4 mb-4">
        <div className="mr-2">
          {step.id}
        </div>
        <div>
          {step.description}
        </div>
      </div>
      <div className={`flex mb-4 ${styles.detailsRow}`}>
        {Object.keys(cols).map((k, i) => <div>
          <div className="text-sm text-dark-grey mb-2">{k}</div>
          <div>{cols[k]}</div>
        </div>)}
      </div>
      {noteInput}
    </div>
  )

  previousTotal = total
  return <Dialog 
    open={true}
    onBackdropClick={onStepSaved}
  >
    <DialogContent>
      { html }
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" color='danger' onClick={removeStep}>Effacer</Button>
      <Button variant="contained" color='danger' onClick={onStepSaved}>Annuler</Button>
      <Button variant="contained" color='primary' onClick={submitStep}>Sauver</Button>
    </DialogActions>
  </Dialog>;
}

export default StepForm
