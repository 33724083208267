import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import DoneIcon from '@material-ui/icons/Done'
import SyncIcon from '@material-ui/icons/Sync'
import UnsyncedIcon from '@material-ui/icons/SyncProblem'
import ErrorIcon from '@material-ui/icons/Warning'
import syncStore from '../utils/syncStore';


const SyncStatus = ({ project }) => {
  const [projectsStatii] = syncStore()

  const icon = (status) => {
    switch (status) {
      case 'error':
        return <ErrorIcon />
      case 'success':
        return <DoneIcon />;
      case 'failed':
      case 'unsynced':
        return <UnsyncedIcon />;
      case 'draft':
        return <SyncIcon />
      case 'pending':
        return <CircularProgress />
      default:
        return null;
    }
  }
  const status = projectsStatii[project.id] || project.status
  return icon(status)
}

export default SyncStatus