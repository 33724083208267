import React, { useState, useMemo, useEffect } from 'react';
import SearchInput from '../../ui/SearchInput';
import { getFilteredStepsAndMeta } from '../../utils/sortProject';
import { KeyboardArrowUpOutlined, KeyboardArrowDownOutlined } from '@material-ui/icons';
import { Collapse } from 'react-collapse';
import Step from './Step';
import { useRoomSteps } from './useRoomSteps';


export const StepCategory = React.memo(({ category, steps, canAdd, dispatch, forceOpen }) => {
  const [isOpen_, setIsOpen] = useState(false)
  const isOpen = isOpen_ || forceOpen

  if (!steps.length) {
    return null
  }

  return <div className="relative w-full">
    <div
      className="w-full pt-3 font-bold -my-px mb-4 mt-2 cursor-pointer bg-white flex justify-between border-b-2 border-yellow py-1 pl-1 sticky z-10 sm:top-0"
      onClick={() => setIsOpen(!isOpen)}
    >
      {category}
      {isOpen ? <KeyboardArrowUpOutlined /> :
        <KeyboardArrowDownOutlined />}
    </div>
    { isOpen && <>
      {
        steps.map((step) => <Step
          key={step.id}
          step={step}
          dispatch={dispatch}
          canAdd
        />)
      }
      <Step
        key={`${category}-new`}
        step={{
          category,
          tags: [],

        }}
        dispatch={dispatch}
        canAdd
      />
    </>}
  </div>
})


export const StepList = ({ }) => {
  const { dispatch, stepList, room: { roomType } } = useRoomSteps()
  const [searchValue, setSearch] = useState('');
  const { filteredGroupedSteps } = useMemo(() => getFilteredStepsAndMeta(stepList, {
    query: searchValue,
    tags: [],
  }), [searchValue, stepList]);
  const stepsEntries = Object.entries(filteredGroupedSteps)
  const forceOpen = searchValue.length || stepsEntries.length <= 1;
  const searchBar = <SearchInput
    className={["color-icon sm:px-4 py-1 pb-2 sticky w-full sm:w-auto sm:mb-4 top-0 z-30 bg-white self-end"].join(' ')}
    value={searchValue}
    onChange={({ target: { value } }) => setSearch(value)} />;

  if(!stepsEntries.length){
  return <div className="flex items-center flex-col py-8">
    Pas d'étapes pour {roomType}
    <br />
    <a 
      href="https://docs.google.com/spreadsheets/d/1n1YLh2pXO4fHZ5QGTJLkIxB4XNxKyvJ1jWFTG1LDSlM/edit?ts=5f8fe83b#gid=0" 
      target="_blank"
      rel='noreferrer'
      className="text-blue mt-1 underline hover:no-underline">
        Ajouter des étapes
      </a>
  </div>
  }
  return <div className="flex flex-col items-end pt-4 sm:pt-6">
    {searchBar}
    <div className="mt-5 sm:mt-6 w-full">
      {stepsEntries.map(([category, steps]) => (
        <StepCategory
          key={category}
          category={category}
          steps={steps}
          dispatch={dispatch}
          forceOpen={forceOpen}
        />
      ))}
    </div>
    
  </div>;
}

export default StepList