import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'

function ButtonLink({ color = 'primary', ...props }) {
  return (
    <Link {...props} className="no-underline font-bold">
      <Button variant="contained" color={color}>
        { props.children }
      </Button>
    </Link>
  )
}

export default ButtonLink
