let url = ""
// url = "http://gri.walidvb.com"
// url = "http://localhost:8000"

export default {
  api: {
    projects: {
      create: `${url}/api/projects`,
      index: `${url}/api/projects`,
      show: id => `${url}/api/projects/${id}`,
      version: (pSlug, vId) => `${url}/api/projects/${pSlug}/versions/${vId}`,
      update: id => `${url}/api/projects/${id}`,
      delete: id => `${url}/api/projects/${id}`,
    },
    steps: {
      index: (providerId) => `${url}/api/provider/${providerId}/items`,
    },
    providers: {
      index: `${url}/api/provider`,
      items: (providerId) => `${url}/api/provider/${providerId}/items`,
    },
    users: {
      show: `${url}/api/user`,
      details: `${url}/api/details`,
    }
  },
  laravel: {
    users: {
      login: `${url}/auth/login/`,
    },
  },
  root: '/',
  versions: {
    xls: (vId) => `/admin/version/${vId}/retrieveXls`,
  },
  projects: {
    index: '/projects',
    new: '/projects/new',
    show: (projectId) => ({
      root: `/projects/${projectId}`,
      versions: {
        index: `/projects/${projectId}`,
        show: (vId) => `/projects/${projectId}/versions/${vId}`,
        quote: (vId) => `/projects/${projectId}/versions/${vId}/quote`,
      },
      form: `/projects/${projectId}/edit`,
      rooms: {
        index: `/projects/${projectId}/rooms`,
        new: `/projects/${projectId}/rooms/new`,
        edit: (id) => `/projects/${projectId}/rooms/${id}/edit`,
      },
      steps: `/projects/${projectId}/steps`,
      quote: `/projects/${projectId}/quote`
    }),
  }
}
