import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import slugify from 'slugify';

import store from './newProjectStore'

function ProjectRoomNew({ onBlur, history }) {
  const [name, setName] = useState("")
  const roomDetails = {
    name,
  }

  const saveRoom = (roomType) => store.addRoom({ ...roomDetails, roomType, slug: slugify(roomDetails.name) }, history)
  return (
    <React.Fragment>
      <div className="flex-1">
        <TextField laabel={'Nom de la pièce'} 
          onBlur={onBlur} 
          placeholder={"Nom de la pièce"}
          onChange={({target: { value }}) => setName(value)} 
          autoFocus={true}
        />
      </div>
      <span className="mr-4">
        <Button  variant="contained" color="primary" onClick={() => saveRoom('humid')}>Pièce Humide</Button>
      </span>
      <span>
        <Button variant="contained" color="primary" onClick={() => saveRoom('dry')}>Pièce Sèche</Button>
      </span>
    </React.Fragment>
  )
}

export default ProjectRoomNew