import { useEffect, useMemo, useCallback } from 'react';
import config from './googleSheets.js'
import usePersistedState from '../utils/usePersistedState';

const headersNameMapToLaravel = {
  "Type de travaux": "room_type",
  "Catégorie": "category",
  "Description": "description",
  "unité par défaut": "unit",
  "quantité par défaut": "default_quantity",
}

const STEPS_RANGE = 'Final!A1:Z1000'
const PRICE_START_INDEX = 5

const filterAndFormatSteps = (steps, providerName, roomType) => {
  const [headers, ...realSteps] = steps
  const columnNames = [...headers].splice(0, PRICE_START_INDEX)

  const providerPriceIndex = headers.indexOf(`${providerName} prix`)
  const categories = new Set()
  const stepList = realSteps.reduce((list, stepAsArray, id) => {
    const price = parseFloat(stepAsArray[providerPriceIndex])
    const min_price = parseFloat(stepAsArray[providerPriceIndex + 1])

    const step = columnNames.reduce((prevObj, keyName, i) => ({
      ...prevObj,
      [headersNameMapToLaravel[keyName]]: stepAsArray[i],
      price: price,
      min_price: isNaN(min_price) ? undefined : min_price,
      // legacy tags support
      tags: [],
    }), { id: id + 1 })

    // dismiss steps with no description or no price so as to filter
    // by providers
    // and filter if room type was defined
    const isNotRoomType = !roomType || step.room_type !== roomType
    const hasNoDescription = !step.description || step.description.length === 0
    const hasNoPrice = isNaN(step.price)
    if(isNotRoomType || hasNoDescription || hasNoPrice){
      return list
    }
    categories.add(step.category)
    return [...list, step]
  }, [])

  return {
    stepList,
    categories: [...categories],
  }
}

const getProviders = (steps) => {
  if (!steps.length){
    return []
  }
  const providerList = [...steps[0]].splice(PRICE_START_INDEX+1)
  // get every second column header name
  return providerList.reduce((list, curr, i) => ( 
    i % 2 ? list
      : [...list, curr]
  ), [])
}

const getRoomTypes = ([_headers, ...steps]) => [...steps.reduce((prev, currStep) => currStep[0]?.length ? new Set([...prev, currStep[0]]) : prev, [])]

let fetched = false
const useSpreadsheetSteps = () => {
  const [ steps, setSteps ] = usePersistedState('steps', [])
  useEffect(() => {
    if (!window.gapi || fetched){
      console.log('Not fetching because gapi / fetched', !window.gapi, fetched)
      //return
    }
    window.gapi.load('client:auth2', async () => {
      console.log('Fetching now the steps')
      await initClient()
      const { spreadsheets } = window.gapi.client.sheets
      const stepsResult = await spreadsheets.values.get({
        spreadsheetId: config.spreadsheetId,
        range: STEPS_RANGE,
      })
      setSteps(stepsResult.result.values)
      fetched = true
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const providers = useMemo(() => getProviders(steps), [steps])
  const roomTypes = useMemo(() => getRoomTypes(steps), [steps])

  const getStepsFor = useCallback((providerName, roomType) => filterAndFormatSteps(steps, providerName, roomType), [steps])

  return {
    providers,
    roomTypes,
    getStepsFor,
  }
}

export default useSpreadsheetSteps

async function initClient(){
  await window.gapi.client
    .init({
      apiKey: config.apiKey,
      clientId: config.clientId,
      discoveryDocs: config.discoveryDocs,
      scopes: config.scopes,
    })
}