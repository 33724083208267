import React, { useState, useEffect } from 'react'
import { view } from 'react-easy-state'
import CountUp from 'react-countup';

const MyCountUp = ({start, ...props}) => {
  const [lastStart, setStart] = useState(start)
  useEffect(() => {
    setStart(props.end)
  }, [props.end])

  return <>
    <CountUp 
      {...props} 
      start={lastStart}
      separator={"'"} 
      duration={.3} 
      useEasing={false} 
    />
  </>
}

export default MyCountUp
