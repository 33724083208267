import React from 'react'
import { view } from 'react-easy-state'
import StepRow from './quote/StepRow'

import { groupStepsByCategory } from '../utils/sortProject'

import styles from './RoomSummary.module.css'
import TitleWithBar from '../ui/TitleWithBar';
import { computeRoomTotal } from '../utils/pricing';
import TableHeader from './TableHeader';

function RoomSummary({ room, editable = true, noResult, previewOnly, withName, withNote, prevStepsCount }) {
  const { steps = [] } = room
  const { groupedSteps } = groupStepsByCategory(steps)
  if (!room.steps.length) {
    return noResult || null
  }

  // function renderAsTable(){
  //   return <table className={["w-full striped-table"].join(' ')} border="0">
  //       <tbody>
  //         {Object.keys(groupedSteps).map(category => {
  //           return <React.Fragment>
  //             <tr className="ignore-stripe">
  //               <td className="bg-yellow py-2 px-1 w-16 mb-4"></td>
  //               <td className="bg-yellow py-2 px-4">{category}</td>
  //               <td colSpan={3}></td>
  //             </tr>
  //             <tr className="ignore-stripe"><td colSpan={5} className={"pt-4"}></td></tr>
  //             {groupedSteps[category].map(s => <StepRow room={room} editable={editable} step={s} previewOnly={previewOnly}/>)}
  //           </React.Fragment>
  //         })}
  //       </tbody>
  //     </table>
  // }
  function renderAsGrid(){
    const gridClasses = [
      styles.grid,
      'bg-white',
    ]
    if (previewOnly){
      gridClasses.push(styles.grid5cols)
    }
    let index = prevStepsCount
    return <div className={gridClasses.join(' ')}>
      {Object.keys(groupedSteps).map(category => (
        <div key={`cat-${room.slug}`} className="contents">
          <div className={['w-full rounded-sm', styles.gridCategory, styles.spanAll].join(' ')}>{category}</div>
          <TableHeader previewOnly={previewOnly} className={`hidden sm:contents ${styles.spanAll}`} />
          {groupedSteps[category].map(s => <StepRow
            key={`sr-${room.slug}-${s.id}`} 
            className="bg-light-grey" 
            room={room} 
            editable={editable} 
            step={s} 
            previewOnly={previewOnly} 
            withUnit={true}
            index={index++}
          />)}
        </div>
      ))}
    </div>
  }

  return <React.Fragment>
      { withName && <TitleWithBar title={room.name || 'Sans Titre'} className="mt-10" /> }
      { withNote && room.note && <div className="note-container pb-6 mt-6 no-input">
        <span className="text-blue">Note:</span>
        {room.note}
      </div>}
      { renderAsGrid() }
      <div className="flex justify-end pr-2 font-bold py-2">
        Total: CHF {computeRoomTotal(room).toLocaleString()}
      </div>
  </React.Fragment>
}

export default view(RoomSummary)
