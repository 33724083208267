import { useState, useEffect } from 'react';
import axios from 'axios'
import routes from './routes'

const storedUser = JSON.parse(localStorage.getItem('gri.user'))

const useUserStore = () => {
  const [user, setUser] = useState(storedUser)
  const [loading, setLoading] = useState(false) 
  useEffect(() => {
    axios
      .get(routes.api.users.details)
      .then(({ data: { data }}) => {
        setUser(data)
        // this should not be needed if GET returns the old request (y) 
        localStorage.setItem('gri.user', JSON.stringify(data))
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        if (err.response && err.response.status === 401){
          window.location = routes.laravel.users.login
        }
      })
  }, [])

  return [user, loading]
}

export default useUserStore