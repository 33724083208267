import React from 'react'
import { Grid, TextField } from '@material-ui/core';
import { Search, HighlightOff } from '@material-ui/icons'
function SearchInput({ onChange, value, className = ''}) {
  return (
    <div className={className}>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item>
          <Search />
        </Grid>
        <Grid item>
          <TextField
            value={value}
            onChange={onChange}
            placeholder="Rechercher"
          />
        </Grid>
        { value.length > 0 && <Grid item>
          <HighlightOff className="opacity-50 cursor-pointer" onClick={() => onChange({ target: { value: '' } })} />
          </Grid>
        }
      </Grid>
    </div>
  )
}

export default SearchInput
