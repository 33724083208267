import React from 'react';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';

import history from './history'
import './index.css';
import './index.tailwind.build.css';
import './App.css';
import ProjectList from './projectList/ProjectList'
import ProjectInfoForm from './project/ProjectInfoForm'
import ProjectShow from './project/ProjectShow';
import Syncer from './serviceWorkerComponents/Syncer'

import routes from './routes'
import axios from 'axios'
import theme from './theme'
import ProjectVersion from './project/ProjectVersion';
import ProjectVersionIndex from './project/ProjectVersionIndex';
import userStore from './userStore';
import Loader from './ui/Loader';
import Page404 from './Page404';
import './config/fullstory';
import { Feedback } from './ui/feedback';

axios.defaults.withCredentials = true;

function App() {
  const [user, loading] = userStore()
  if(loading){
    return <Loader withContainer notice="loading user"/>
  }
  if(!user){
    return <div className="container text-center mx-auto py-8 font-2xl">
      <a href={`//gri.walidvb.com/auth/login?redirect_to=${encodeURIComponent(window.location.href)}`}>
        Identifiez-vous
      </a>
    </div>
  }
  return (
    <SnackbarProvider maxSnack={1000}>
      <ThemeProvider theme={theme}>
        <div className="App">
            <Router history={ history }>
              <Route path="/" component={Feedback} />
              <Switch>
                <Route path={[routes.projects.index, routes.root]} exact={true} component={ProjectList} />
                <Route path={routes.projects.new} component={ProjectInfoForm} />
                <Route path={routes.projects.show(':projectSlug').versions.quote(':vId')} component={ProjectVersion} />
                <Route exact={true} path={routes.projects.show(':projectSlug').versions.index} component={ProjectVersionIndex} />
                <Route path={routes.projects.show(':projectSlug').root} component={ProjectShow} />
                <Route component={Page404} />
              </Switch>
            </Router>
            <Syncer />
        </div>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
