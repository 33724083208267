import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: { main: "#FFCC33" }, // yellow
    secondary: { main: '#7774FF' },
    cancel: { main: '#74B4FF' },
  },
  overrides: {
    MuiStepIcon: {
      root: {
        opacity: .5,
        "&$active": {
          opacity: 1,
        },
        "&$completed": {
          opacity: 1,
        }
      },
    },
    MuiStepLabel: {
      label: {
        opacity: .5,
        fontWeight: 'bold!important',
      },
      active: {
        opacity: 1
      },
      completed: {
        opacity: 1,
      }
    }
  }
});