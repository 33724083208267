import React from 'react'
import moment from 'moment'
import userStore from '../../userStore';

const QuoteDetails = ({project}) => {
  const date = moment(new Date()).format("DD/MM/YYYY")
  const user = userStore()[0]
  // TODO: [UI] add contract number
  const { address, name, details, internal_id, based_on_your_request } = project.client
  return (
    <div>
      <div>
        <span className="font-bold">OFFRE N<sup>o</sup>:</span> {internal_id}
      </div>
      <div className="flex mb-2">
        <div className="flex-1 font-bold uppercase"><span className="text-dark-grey">Concerne: </span>{project.title}</div>
        <div>{date}</div>
      </div>
      <div>
        { (address && address.length) && 
          <>
            <span className="text-dark-grey">Adresse:</span> 
            {address}
            <br />
          </>
        }
        { (name  && name.length) && 
          <><span className="text-dark-grey">Contact:</span> {name} <br /></>
        }
        { (based_on_your_request  && based_on_your_request.length) && 
          <><span className="text-dark-grey">Selon votre demande de devis N<span>o</span>:</span> {based_on_your_request} <br /></>
        }
        { (details && details.length) && 
          <span><span className="text-dark-grey">Note:</span> {details}</span>
        }
      </div>
      <div className="mt-2">
        <span className="text-dark-grey">Votre contact:</span> {user?.name}
      </div>
    </div>
  )
}

export default QuoteDetails