import React, { useEffect } from 'react'
import { Route, Switch } from "react-router-dom";
import { view } from 'react-easy-state'

import ProjectInfoForm from './ProjectInfoForm'
import ProjectRooms from './ProjectRooms'
import Quote from './quote/Quote'

import routes from '../routes';
import store from './newProjectStore'
import Loader from '../ui/Loader';
import projectsAPI from '../utils/projectsAPI';
import RoomEdit from './room/RoomEdit';


function ProjectShow({ match: { params: { projectSlug }} }){
  useEffect(() => {
    (async function(){
      try{
        store.project = await projectsAPI.get(projectSlug)
      } catch(err) {
        console.log(err, "not found")
      }
    })()
  }, [projectSlug])

  if(!store.project){
    return <Loader withContainer={true} />
  }
  const errorsComponent = store.errors && <div>
    {Object.keys(store.errors).map(k => <div>{store.errors[k]}</div>)}
  </div>
  return (
    <Switch>
      <Route path={routes.projects.show(':projectSlug').rooms.index} component={ProjectRooms}  errors={errorsComponent}/>
      {/* <Route path={routes.projects.show(':projectSlug').rooms.edit(":id")} component={ProjectRoomEdit} errors={errorsComponent} /> */}
      <Route path={routes.projects.show(':projectSlug').rooms.edit(":id")} component={RoomEdit} errors={errorsComponent} />
      <Route path={routes.projects.show(':projectSlug').quote} component={({ history }) => <Quote project={store.project} history={history} errors={errorsComponent}/>} />
      <Route path={routes.projects.show(':projectSlug').form} component={ProjectInfoForm} errors={errorsComponent}/>
      {/* <Route path={routes.projects.show(':projectSlug').versions.quote(':vId')} component={ProjectVersion} /> */}
      {/* <Route path={routes.projects.show(':projectSlug').versions.index} component={ProjectVersionIndex} /> */}
    </Switch>
  )
}

export default view(ProjectShow)