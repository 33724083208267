import React from 'react'
import { CircularProgress } from '@material-ui/core';
function Loader({ withContainer, notice }) {
  const loader = <div className="text-center">
    <CircularProgress />
    {notice && <div className="text-gray-600 mt-4 italic">{notice}</div>}
  </div>
  return (
    withContainer ? <div className="container mx-auto flex justify-center py-16">{loader}</div> : loader
  )
}

export default Loader
