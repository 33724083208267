import React, { useState, useEffect } from 'react'
import { view } from 'react-easy-state'
import { Button, Select, TextField, MenuItem, InputLabel, FormControl } from '@material-ui/core';


import routes from '../routes';
import MenuBar from '../ui/MenuBar';
import projectController from '../utils/projectController';
import Errors from '../ui/Errors';
import useSpreadsheetSteps from '../steps/useSpreadsheetSteps';


function ProjectInfoForm({ history }) {
  const [project, setProject] = useState({})
  const [client, setClient] = useState({})
  const [persisted] = useState(false)
  const { providers } = useSpreadsheetSteps()

  // TODO: [DATA] add more client info
  const updateProject = (key, value) => {
    setProject({
      ...project,
      [key]: value
    })
  }
  const updateClient = (key, value) => {
    setClient({
      ...client,
      [key]: value
    })
  }

  const createProject = async () => {
    if(!project.provider_name || ! project.title){
      return
    }
    const proj = await projectController.create({
      ...project,
      client,
    }, history)
    setProject(proj)
  }
  const saveButton = <Button variant="contained" color="secondary" onClick={createProject}>Sauver </Button>
  const cleanProviderName = name => name.replace(/ ?prix/, '')
  return (
    <React.Fragment>
      <MenuBar title={persisted ? project.title : 'Nouveau Projet'} right={saveButton} backTo={routes.projects.index}/>
      <Errors errors={project.errors} />
      <div className="max-w-lg w-full flex flex-col mx-auto mt-8 px-2">
        <TextField
          label="N° Offre"
          name="internal_id"
          defaultValue={project.internal_id}
          multiline={true}
          rows={2}
          placeholder="648526 FL"
          autoFocus={!persisted}
          onChange={({ target: { value } }) => updateProject('internal_id', value)}
        />
        <div className="h-2"/>
        <TextField
          required 
          error={project.title === ""} 
          label="Concerne:" 
          name="title" 
          defaultValue={project.title} 
          onChange={({ target: { value } }) => updateProject('title', value)}
        />
        <div className="h-2"/>
        <TextField
          label="Adresse:"
          name="adress"
          defaultValue={client.address}
          multiline={true}
          rows={2}
          placeholder="1, rue de l'Industrie 1227 Les Acacias"
          onChange={({ target: { value } }) => updateClient('address', value)}
        />
        <div className="h-2" />
        <TextField
          label="Nom du client"
          name="clientName"
          defaultValue={client.name}
          onChange={({ target: { value } }) => updateClient('name', value)}
        />
        <div className="h-2" />
        <TextField
          label="Selon le devis:"
          name="based_on_your_request"
          defaultValue={project.based_on_your_request}
          multiline={true}
          rows={2}
          placeholder="Refonte de la cuisine"
          onChange={({ target: { value } }) => updateProject('based_on_your_request', value)}
        />
        <div className="h-2" />
        <TextField
          label="Détails"
          name="details"
          className="mt-4"
          defaultValue={project.details}
          multiline={true}
          rows={2}
          placeholder="code porte: 80085"
          onChange={({ target: { value } }) => updateClient('details', value)}
        />
        <FormControl style={{marginTop: '16px'}}>
          <InputLabel htmlFor="provider_name">Régie</InputLabel>
          <Select
            required
            value={project.provider_name }
            onChange={({ target: { value } }) => updateProject('provider_name', value)}
            inputProps={{
              name: 'provider_name',
              id: 'provider_name'
            }}
          >
            {providers.map((p, index) => <MenuItem value={cleanProviderName(p)}>{cleanProviderName(p)}</MenuItem>)}
          </Select>
        </FormControl>
        <div className="h-2" />
        {/* <h2 className="mt-12">Détails Client</h2>
        <div className="h-2" />
        {/* TODO: [EST] format phone number */}
        <TextField
          label="Téléphone"
          name="phone"
          defaultValue={client.telephone}
          onChange={({ target: { value } }) => updateClient('telephone', value)}
        />
        <div className="inline-flex  mt-6 justify-end">
          {saveButton}
        </div>
      </div>
    </React.Fragment>
  )
}

export default view(ProjectInfoForm)
