import { useSnackbar } from 'notistack';
import { useRouteMatch } from 'react-router-dom';
import { useEffect, useCallback } from 'react';

export const Feedback = ({ location: { state } }) => {
  const feedback = useFeedback()
  useEffect(() => {
    if (state) {
      const { lastAction } = state
      if (lastAction) {
        feedback(lastAction.action, lastAction.status)
      }
    }
  }, [state,feedback])
  return null
}

const useFeedback = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback((action, variant) => enqueueSnackbar(action, {
    variant: variant || 'info',
    autoHideDuration: 2000,
  }), [enqueueSnackbar])
}

export default useFeedback