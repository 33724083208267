import Fuse from 'fuse.js';


const fuseOptions = {
  threshold: .15,
  location: 0,
  distance: 1000,
  keys: ['description', 'category'],
  shouldSort: true,
}

const stepHasRoomType = ({ room_type }, roomType) => room_type.length && roomType && room_type.split(',').includes(roomType)

export function groupStepsByCategory(steps) {
  const groupedSteps = {}
  const tags = new Set()
  steps.forEach(s => {
    const cat = s.category
    s.tags && s.tags.forEach(t => tags.add(t))
    groupedSteps[cat] = groupedSteps[cat] || []
    groupedSteps[cat].push(s)
  })
  return {
    tags: [...tags].map(t => ({
      name: t,
    })),
    groupedSteps,
  }
}

export function filterSteps(groupedSteps, { query = '', tags = [], roomType }) {
  const filtered = { }
  Object.keys(groupedSteps).forEach(category => {
    const steps = groupedSteps[category]
    const searcher = new Fuse(steps, fuseOptions)
    let steps_ = steps
    if (query !== '') {
      steps_ = searcher.search(query)
    }
  
    if (tags.length || roomType) {
      steps_ = steps_.filter(s => {
        let includeStep = true
        if (tags.length) {
          includeStep = includeStep && tags.reduce((prev, curr) => prev || s.tags.includes(curr.name), false)
        }
        if(roomType){
          includeStep = includeStep && stepHasRoomType(s, roomType)
        }
        return includeStep
      })
    }
    filtered[category] = steps_
  })
  
  return filtered
}

export function getFilteredStepsAndMeta(steps, options){
  const { tags, groupedSteps } = groupStepsByCategory(steps, options.roomType)
  const filteredGroupedSteps = filterSteps(groupedSteps, options)
  return {
    tags,
    categories: Object.keys(groupedSteps),
    groupedSteps,
    filteredGroupedSteps
  }
}
