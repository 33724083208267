import React from 'react'

import styles from './RoomSummary.module.css'

function TableHeader({ previewOnly, className='', withGridWrapper = false }) {
  
  const row = (
  <div className={`text-light-black table-header ${className}`}>
      <div></div>
      <div className={styles.th}>
        <div>Description</div>
      </div>
      <div className={styles.th}> Quantités </div>
      <div className={styles.th}> Prix unitaire </div>
      <div className={`${styles.th} text-right`}> Total </div>
      {!previewOnly && <div className={styles.th}></div>}
    </div>
  )

  return withGridWrapper ? <div className={`${styles.grid} ${styles.grid5cols}`}>{row}</div> : row
}

export default TableHeader