import React, { PureComponent } from 'react'
import { computeProjectTotal, countProjectSteps } from '../../utils/pricing';
import MyCountUp from '../../ui/CountUp';
import { Card } from '@material-ui/core';

export default class QuoteStatistics extends PureComponent {
  render() {
    const { project } = this.props
    const stats= [
      {
        // TODO: [EST] don't count general works
        number: project.version.rooms.length,
        label: 'Pièces',
      },
      {
        number: countProjectSteps(project),
        label: 'Travaux',
      },
      {
        label: 'Total CHF',
        number: <div><MyCountUp end={computeProjectTotal(project)} /></div>,
      }
    ]
    return (
      <div className="w-full bg-light-grey py-8">
        <div className="container mx-auto flex justify-around">
          {stats.map(s =>(
              <Card key={s.label}>
                <div className="text-4xl font-bold text-center px-12 py-4 text-blue">{s.number}</div>
                {s.label && <div className="text-lg text-center font-bold bg-light-grey px-12 py-4">{s.label}</div>}
            </Card>
          ))}
        </div>
      </div>
    )
  }
}