import routes from '../routes'
import projectsAPI from './projectsAPI';
import slugify from 'slugify';

import store from '../project/newProjectStore'


const projectController = {
  index: async () => await projectsAPI.index(),
  get: (projectSlug, versionId) => {
    return projectsAPI.get(projectSlug, versionId)
  },
  create: async (project, history) => {
    try{
      var createdProject = await projectsAPI.create({
        slug: slugify(project.internal_id),
        ...project,
      })
      if(createdProject){
        history.push(routes.projects.show(createdProject.slug).rooms.index)
        return createdProject
      }
      throw Error("Project not created")
    } catch(err){
      if (!err.response){
        throw err
      }
      const { response: { data: { data: errors } } } = err
      return {
        ...project,
        errors
      }
    }
  },
  lock: async (data, history) => {
    store.reset = true
    const { version, ...project} = data
    store.project = await projectsAPI.save({
      ...project,
      version: {
        ...version,
        locked: true,
      }
      
    })
  },
  save: async (project) => {
    try{
      store.reset = true
      return await projectsAPI.save(project)
    } catch(err){
      const {response: { data: { data: errors}}} = err
      console.log('errors', errors)
      return {
        ...project,
        status: 'failed',
        errors
      }

    }
  }
}
export default projectController