import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

function MyDialog({
  title, content, acceptText = 'Accepter', cancelText = 'Annuler',
  open, onAccept, onCancel, onBackdropClick = onCancel
}) {

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onBackdropClick={onBackdropClick}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onCancel} color="cancel">
          { cancelText }
          </Button>
        <Button variant="contained" onClick={() => onAccept(onCancel)} color="primary" autoFocus>
          { acceptText }
          </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MyDialog
