import styled from 'styled-components'
import tw from 'tailwind-styled-components'


const Div = styled.div`
  background-color: var(--bg-grey);
  @media (max-width: 768px){
    :nth-child(2n+1){
      background-color: white;
    }
  }
`

const CardItem = tw(Div)`
  p-4
  rounded-xl
`
export default CardItem